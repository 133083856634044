<template>
  <div class="detail">
    <div>
      <div class="align-middle inline-block min-w-full">
        <div class="overflow-hidden border border-gray-200 rounded">
          <table class="min-w-full divide-y divide-gray-200 table-fixed">
            <thead class="">
              <tr class="divide-x divide-gray-200">
                <th v-for="(c, index) in columns" :key="index" scope="col" :rowspan="c.rowspan" :colspan="c.colspan" :class="c.class" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {{c.title}}
                </th>
                <th rowspan="2" class="px-0.5 py-0.5 whitespace-nowrap text-center text-sm font-medium col-actions">
                  <div class="flex justify-center">
                    <a href="#" @click="deleteAll">
                      <TrashIcon class="text-red-500 cursor-pointer hover:text-red-600 active:text-red-700 w-5 h-5" />
                    </a>
                  </div>
                </th>
              </tr>

              <tr class="divide-x divide-gray-200">
                <th v-for="(ic, j) in itemColumns" :key="j" scope="col" :colspan="ic.colspan" :class="ic.class" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-detail">
                  {{ic.title}}
                </th>
              </tr>
            </thead>

            <draggable 
              :key="renderKey"
              ref="tableBody"
              :list="value"
              direction="vertical"
              handle=".row-drag-handle"
              tag="tbody" 
              class="bg-white divide-y divide-gray-200"
              @change="detailOrderChanged"
            >
              <tr v-for="(detail, index) in value" :key="index" class="divide-x divide-gray-200">

                <!-- No. -->
                <td v-if="detail.rowspan!=-1" :rowspan="detail.rowspan" class="text-center px-0.5 py-0.5 whitespace-nowrap text-sm font-medium text-gray-900 col-no">
                  <template v-if="sortable">
                    <div class="flex justify-center items-center row-drag-handle cursor-pointer">
                      <MenuIcon class="text-gray-400" />
                    </div>
                  </template>
                  <template v-else>
                    {{ setNo(index + 1) }}
                  </template>
                </td>

                <!-- カテゴリー -->
                <td v-if="detail.rowspan!=-1" :rowspan="detail.rowspan" class="px-0.5 py-0.5 text-sm text-gray-500">
                  <WmsSuggestInput
                    name="categoryName"
                    :embed="true"
                    displayField="categoryName"
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="categorySelectionItems"
                    class="embed"
                    v-model="detail.categoryName"
                    @selected="categorySelected(detail, $event, index)"
                    @change="categoryChange(detail)"
                    @onEnterKey="nextField"
                  />
                </td>

                <!-- 詳細内容 -->
                <td class="px-0.5 py-0.5 text-sm text-gray-500 border-l" v-tooltip="['3', '4', '5'].includes(value[index].categoryCode) ? '' : value[index].listForTooltip">
                  <div class="flex">
                    <div class="mx-2 my-auto py-1 whitespace-pre-line break-words">
                      {{ detail.itemName }}
                    </div>
                    <Icon
                      v-if="isIcon(index)"
                      iconName="PencilAlt" 
                      :clickable="true" 
                      class="w-5 h-5 ml-auto mt-1 cursor-pointer"
                      :class="setIconBgColor(detail.iconBgColor)"
                      @click="selectProduct(itemMasterName(index), detail, index)"
                    />
                  </div>
                </td>

                <!-- 数量（詳細）-->
                <td :rowspan="setRowspanQty(detail)" v-if="setRowspanQty(detail)>0 && !estimate.columnDragFlagOne" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-qty">
                  <div class="text-right w-full py-1 px-3 sm:text-sm cursor-not-allowed">
                    {{ formatValue(detail.itemQty) }}
                  </div>
                </td>

                <!-- 単位（詳細）-->
                <td :rowspan="setRowspanQty(detail)" v-if="setRowspanQty(detail)>0 && !estimate.columnDragFlagOne" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-unit-name">
                  <input 
                    autocomplete="off"
                    type="text" 
                    name="itemUnitName" 
                    class="text-center w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent cursor-not-allowed"
                    v-model="detail.itemUnitName"
                    @change="itemNameChanged(detail, index)"
                    :disabled="true"
                  />
                </td>

                <!-- 単価（詳細）ラウンドプラス以外-->
                <td v-if="detail.categoryCode!='2'" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-unit-price">
                  <!-- <NumericTextInput
                    name="itemUnitPrice"
                    v-model="detail.itemUnitPrice"
                    class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                    :class="setPrice1BgColor(detail.detailBgColor || detail.trashTimesBgColor, detail.iconBgColor, detail.categoryCode, index, detail.type, detail)"
                    @onEnterKey="nextField"
                    @change="itemModalChanged(detail, index); setPriceChange(index, 'detail')"
                    :disabled="true"
                  /> -->
                  <div
                    class="text-right w-full py-1 px-3 sm:text-sm cursor-not-allowed"
                    :class="setPrice1BgColor(detail.detailBgColor || detail.trashTimesBgColor, detail.iconBgColor, detail.categoryCode, index, detail.type, detail)">
                    {{ formatValue(detail.itemUnitPrice) }}
                  </div>
                </td>
                <!-- 単価（詳細）ラウンドプラス 単価を表示しない -->
                <td v-else-if="detail.categoryCode=='2' && !detail.isUnitPrice && detail.rowspan!=-1" :rowspan="detail.rowspan" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-unit-price">
                  <!-- <NumericTextInput
                    name="itemUnitPrice"
                    v-model="detail.itemUnitPrice"
                    class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent h-full"
                    :class="setBgColor(detail.detailBgColor || detail.iconBgColor)"
                    :disabled="true"
                  /> -->
                  <div
                    class="text-right w-full py-1 px-3 sm:text-sm cursor-not-allowed"
                    :class="setBgColor(detail.detailBgColor || detail.iconBgColor)">
                    {{ formatValue(detail.itemUnitPrice) }}
                  </div>
                </td>
                <!-- 単価（詳細）ラウンドプラス 単価を表示 -->
                <td v-else-if="detail.categoryCode=='2' && detail.isUnitPrice && detail.fixedRowspan!=-1" :rowspan="!detail.fixedRowspan?1:detail.fixedRowspan" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-unit-price">
                  <!-- <NumericTextInput
                    name="itemUnitPrice"
                    v-model="detail.itemUnitPrice"
                    class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent h-full"
                    :class="setBgColor(detail.detailBgColor || detail.iconBgColor2)"
                    :disabled="true"
                  /> -->
                  <div
                    class="text-right w-full py-1 px-3 sm:text-sm cursor-not-allowed"
                    :class="setBgColor(detail.detailBgColor || detail.iconBgColor2)">
                    {{ formatValue(detail.itemUnitPrice) }}
                  </div>
                </td>
                
                <!-- 数量（詳細）-->
                <td :rowspan="setRowspanQty(detail)" v-if="setRowspanQty(detail)>0 && estimate.columnDragFlagOne" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-qty">
                  <div class="text-right w-full py-1 px-3 sm:text-sm cursor-not-allowed">
                    {{ formatValue(detail.itemQty) }}
                  </div>
                </td>

                <!-- 単位（詳細）-->
                <td :rowspan="setRowspanQty(detail)" v-if="setRowspanQty(detail)>0 && estimate.columnDragFlagOne" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-unit-name">
                  <input 
                    autocomplete="off"
                    type="text" 
                    name="itemUnitName" 
                    class="text-center w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent cursor-not-allowed"
                    v-model="detail.itemUnitName"
                    @change="itemNameChanged(detail, index)"
                    :disabled="true"
                  />
                </td>

                <!-- 単価（カテゴリー） 特別清掃以外 -->
                <td v-if="!estimate.columnDragFlag && !(detail.categoryCode=='14' && !detail.newData) && setRowspan(detail)!=-1" :rowspan="setRowspan(detail)" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-unit-price">
                  <!-- <NumericTextInput
                    name="categoryUnitPrice"
                    v-model="detail.categoryUnitPrice"
                    class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent h-full"
                    :class="[setBgColor(detail.categoryBgColor), detail.categoryCode=='2'?'bg-gray-50' : '']"
                    @onEnterKey="nextField"
                    @change="changeCategoryPrice(detail, index)"
                    :disabled="true"
                  /> -->
                  <div
                    class="text-right w-full py-1 px-3 sm:text-sm cursor-not-allowed"
                    :class="[setBgColor(detail.categoryBgColor), detail.categoryCode=='2'?'bg-gray-50' : '']">
                    {{ formatValue(detail.categoryUnitPrice) }}
                  </div>
                </td>

                <!-- 単価（カテゴリー） 特別清掃 過去仕様 -->
                <td v-if="!estimate.columnDragFlag && (detail.categoryCode=='14' && !detail.newData)" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-unit-price">
                  <!-- <NumericTextInput
                    name="categoryUnitPrice"
                    v-model="detail.categoryUnitPrice"
                    class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent h-full"
                    :class="setBgColor(detail.categoryBgColor)"
                    @onEnterKey="nextField"
                    @change="changeCategoryPrice(detail, index)"
                    :disabled="true"
                  /> -->
                  <div
                    class="text-right w-full py-1 px-3 sm:text-sm cursor-not-allowed"
                    :class="setBgColor(detail.categoryBgColor)">
                    {{ formatValue(detail.categoryUnitPrice) }}
                  </div>
                </td>

                <!-- 数量（カテゴリー）特別清掃以外 -->
                <td v-if="!(detail.categoryCode=='14' && !detail.newData) && setRowspan(detail)!=-1" :rowspan="setRowspan(detail)" v-tooltip="detail.categoryCode=='15'?'消防の数量2は詳細モーダルで変更してください':''" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-qty">
                  <NumericTextInput
                    name="categoryQty"
                    v-model="detail.categoryQty"
                    class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent h-full"
                    @onEnterKey="nextField"
                    @change="categoryModalChanged(detail, index)"
                    :disabled="true"
                  />
                </td>
                <!-- 数量（カテゴリー）特別清掃 過去仕様-->
                <td v-if="(detail.categoryCode=='14' && !detail.newData)" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-qty">
                  <NumericTextInput
                    name="categoryQty"
                    v-model="detail.categoryQty"
                    class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent h-full"
                    @onEnterKey="nextField"
                    @change="categoryModalChanged(detail, index)"
                    :disabled="true"
                  />
                </td>

                <!-- 単位（カテゴリー）特別清掃以外 -->
                <td v-if="!(detail.categoryCode=='14' && !detail.newData) && setRowspan(detail)!=-1" :rowspan="setRowspan(detail)" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-unit-name">
                  <input
                    autocomplete="off"
                    name="categoryUnitName"
                    v-model="detail.categoryUnitName"
                    class="w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent text-center h-full cursor-not-allowed bg-white"
                    @change="categoryNameChanged(detail, index)"
                    :disabled="true"
                  />
                </td>

                <!-- 単位（カテゴリー） 特別清掃 過去仕様 -->
                <td v-if="(detail.categoryCode=='14' && !detail.newData)" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-unit-name">
                  <input
                    autocomplete="off"
                    name="categoryUnitName"
                    v-model="detail.categoryUnitName"
                    class="w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent text-center h-full cursor-not-allowed bg-white"
                    @change="categoryNameChanged(detail, index)"
                    :disabled="true"
                  />
                </td>

                  <!-- 単価（カテゴリー） 特別清掃以外 -->
                <td v-if="estimate.columnDragFlag && !(detail.categoryCode=='14' && !detail.newData) && setRowspan(detail)!=-1" :rowspan="setRowspan(detail)" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-unit-price">
                  <!-- <NumericTextInput
                    name="categoryUnitPrice"
                    v-model="detail.categoryUnitPrice"
                    class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent h-full"
                    :class="[setBgColor(detail.categoryBgColor), detail.categoryCode=='2'?'bg-gray-50' : '']"
                    @onEnterKey="nextField"
                    @change="changeCategoryPrice(detail, index)"
                    :disabled="true"
                  /> -->
                  <div
                    class="text-right w-full py-1 px-3 sm:text-sm cursor-not-allowed"
                    :class="[setBgColor(detail.categoryBgColor), detail.categoryCode=='2'?'bg-gray-50' : '']">
                    {{ formatValue(detail.categoryUnitPrice) }}
                  </div>
                </td>

                <!-- 単価（カテゴリー） 特別清掃 過去仕様 -->
                <td v-if="estimate.columnDragFlag && (detail.categoryCode=='14' && !detail.newData)" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 col-unit-price">
                  <!-- <NumericTextInput
                    name="categoryUnitPrice"
                    v-model="detail.categoryUnitPrice"
                    class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent h-full"
                    :class="setBgColor(detail.categoryBgColor)"
                    @onEnterKey="nextField"
                    @change="changeCategoryPrice(detail, index)"
                    :disabled="true"
                  /> -->
                  <div
                    class="text-right w-full py-1 px-3 sm:text-sm cursor-not-allowed"
                    :class="setBgColor(detail.categoryBgColor)">
                    {{ formatValue(detail.categoryUnitPrice) }}
                  </div>
                </td>

                <!-- 金額 特別清掃以外 -->
                <td v-if="!(detail.categoryCode=='14' && !detail.newData) && setRowspan(detail)!=-1" :rowspan="setRowspan(detail)" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 bg-gray-50 col-amount">
                  <NumericTextInput
                    name="categoryAmount"
                    disabled
                    v-model="detail.categoryAmount"
                    class="text-right w-full py-1 bg-gray-50 block sm:text-sm border-transparent" 
                    @onEnterKey="nextField"
                  />
                </td>

                <!-- 金額 特別清掃 過去仕様 -->
                <td v-if="(detail.categoryCode=='14' && !detail.newData)" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 bg-gray-50 col-amount">
                  <NumericTextInput
                    name="categoryAmount"
                    disabled
                    v-model="detail.categoryAmount"
                    class="text-right w-full py-1 bg-gray-50 block sm:text-sm border-transparent" 
                    @onEnterKey="nextField"
                  />
                </td>

                <!-- 削除-->
                <td v-if="detail.rowspan!=-1" :rowspan="detail.rowspan" class="px-0.5 py-0.5 whitespace-nowrap text-center text-sm font-medium col-actions">
                  <div class="flex justify-center">
                    <a href="#" @click="deleteClicked(index, detail.rowspan)">
                      <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
                    </a>
                  </div>
                </td>
              </tr>
            <!-- </tbody> -->
            </draggable>
            <tfoot>
              <tr class="divide-x" v-if="estimate.discount&&estimate.discount!='0'">
                <td :colspan="estimate.columnDragFlag ? 8 : 7"></td>
                <th :colspan="estimate.columnDragFlag ? 1 : 2" scope="row" class="border-b border-gray-200 text-red-500">調整費</th>
                <td class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 bg-white border-b border-gray-200">
                  <NumericTextInput
                    name="discount"
                    class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent" 
                    :class="estimate.discount!=''&&estimate.discount!='0'?'bg-yellow-200':''"
                    v-model="estimate.discount"
                  />
                </td>
              </tr>
              <tr class="divide-x">
                <td :colspan="estimate.columnDragFlag ? 8 : 7"></td>
                <th :colspan="estimate.columnDragFlag ? 1 : 2" class="border-b border-gray-200 text-gray-600">小計</th>
                <td class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 bg-gray-100 border-b border-gray-200">
                  <NumericTextInput
                    name="tax"
                    disabled
                    class="text-right w-full py-1 bg-gray-100 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent" 
                    v-model="estimate.withoutTax"
                  />
                </td>
                <td class="border-b border-gray-200">
                  <div class="text-center flex justify-center">
                    <PlusCircleIcon class="text-indigo-500 hover:text-indigo-700 active:text-indigo-600 cursor-pointer" @click="addRow" />
                  </div>
                </td>
              </tr>
              <tr class="divide-x">
                <td :colspan="estimate.columnDragFlag ? 8 : 7"></td>
                <th :colspan="estimate.columnDragFlag ? 1 : 2" class="border-b border-gray-200 text-gray-600">消費税</th>
                <td class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-500 bg-gray-100 border-b border-gray-200">
                  <NumericTextInput
                    name="tax"
                    disabled
                    class="text-right w-full py-1 bg-gray-100 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent" 
                    v-model="estimate.tax"
                  />
                </td>
                <td></td>
              </tr>
              <tr class="text-lg divide-x">
                <td :colspan="estimate.columnDragFlag ? 8 : 7"></td>
                <th :colspan="estimate.columnDragFlag ? 1 : 2" class="text-gray-600 font-bold">合計</th>
                <td class="text-gray-600 font-bold text-right p-2">¥ {{ formatNumber(totalAmount) }}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <!-- 中項目モーダル ラウンドプラス以外 -->
    <EstimateDetailSubItemModal 
      v-model="dataSelectorShow" 
      :categoryCode="dataSelectorParams.categoryCode"
      :categoryName="dataSelectorParams.categoryName"
      :buildingInfo="estimate.buildingInfo"
      :modalData="dataSelectorParams.modalData"
      :rows="dataSelectorParams.group"
      :index="dataSelectorParams.index"
      :open="clickCount"
      :purchaseData="purchaseData"
      :commissionData="commissionData"
      :selectCallback="dataSelectorParams.selectCallback"
      :show="dataSelectorShow"
    />

    <!-- 中項目モーダル ラウンドプラス -->
    <EstimateDetailRlsPlusModal 
      v-model="dataSelectorShowRlsPlus" 
      :categoryCode="dataSelectorParams.categoryCode"
      :categoryName="dataSelectorParams.categoryName"
      :buildingInfo="estimate.buildingInfo"
      :modalData="dataSelectorParams.modalData"
      :row="dataSelectorParams.row"
      :index="dataSelectorParams.index"
      :open="clickCountRlsPlus"
      :purchaseData="purchaseData"
      :commissionData="commissionData"
      :selectCallback="dataSelectorParams.selectCallback"
    />

  </div>
</template>

<script>
import draggable from "vuedraggable"
import { TrashIcon, PlusCircleIcon, MenuIcon } from '@vue-hero-icons/solid'
import NumericTextInput from '@components/NumericTextInput.vue'
import Icon from '@components/Icon.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import EstimateDetailSubItemModal from './EstimateDetailSubItemModal.vue'
import EstimateDetailRlsPlusModal from './EstimateDetailRlsPlusModal.vue'

import * as estimateManager from '@managers/estimateManager'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'

import { FEATURES } from '@/config'
// const draggableOptions = {
//   direction: 'vertical'
// }

export default {
  components: {
    WmsSuggestInput,
    TrashIcon,
    PlusCircleIcon,
    MenuIcon,
    NumericTextInput,
    draggable,
    Icon,
    EstimateDetailSubItemModal,
    EstimateDetailRlsPlusModal
  },

  props: ['estimate', 'value', 'sortable'],
  
  data() {
    return {
      renderKey: 0,

      dataSelectorShow: false,
      dataSelectorShowRlsPlus: false,
      dataSelectorParams: {
        categoryCode: null,
        categoryName: '',
        modalData: {}
      },
      clickCount: 100,
      clickCountRlsPlus: 100,
      purchaseData: [],
      commissionData: {},
      // itemColumns: [
      //     {title: '内容', colspan: 1, class: 'w-300'},
      //     {title: '数量', colspan: 2, class: 'w-160'},
      //     {title: '単価', colspan: 1, class: 'w-170'}
        // ]
    }
  },

  methods: {

    /**
     * カテゴリー選択イベント
     * @param detail 1行のデータ
     * @param selectedcategory 選択したカテゴリ
     * @param index インデックス
     */
    async categorySelected(detail, selectedcategory, index) {
      // 既に詳細モーダルで金額確定している場合
      if (detail.subItemModalData && Object.keys(detail.subItemModalData).length) {
        let name = detail.subItemModalData.purchase.categoryName
        if (!name) {
          name = ''
        }
        if (selectedcategory.categoryCode != detail.categoryCode) {
          detail.categoryName = name
          await dialogs.showWarningDialog('違うカテゴリーを選択できません', '既に詳細モーダルで1度完了しています。\r\n違うカテゴリーにする場合は行を新たに追加してください。\r\n名前のみ変更します。')
        }
        return false
      }
      // 紐づく特記事項の行を削除
      this.$emit('deleteNoteList', detail.groupName)

      // 単独発注か判定
      if (this.value.length == 1) {
        this.estimate.buildingInfo.other = '単独'
      } else {
        let count = 0
        for (let i = 0; i < this.value.length; i++) {
          if (this.value[i].categoryName != '') {
            count ++
            break
          }
        }
        if (count > 0) {
          this.estimate.buildingInfo.other = '他あり'
        } else {
          this.estimate.buildingInfo.other = '単独'
        }
      }

      detail.categoryName = selectedcategory.categoryName
      detail.categoryCode = selectedcategory.categoryCode
      // グループ名をセット
      detail.groupName = selectedcategory.categoryName + Math.floor(Math.random()*90000) + 10000
      // カテゴリーの数量をセット
      detail.categoryQty = selectedcategory.qty
      // カテゴリーの単位をセット
      detail.categoryUnitName = selectedcategory.unitName

      // ガラス清掃の場合、リクエストポストの希望回数を入れる
      if (selectedcategory.categoryCode == '10') {
        detail.categoryQty = this.estimate.buildingInfo.glassTimes

      // 植栽剪定
      } else if (selectedcategory.categoryCode == '13' && this.estimate.buildingInfo.plantingTimes) {
        detail.categoryQty = this.estimate.buildingInfo.plantingTimes

      // 管球交換の場合、詳細単位を「個」で初期値
      } else if (selectedcategory.categoryCode == '23') {
        detail.itemUnitName = '個'

      // 定期
      } else if (selectedcategory.categoryCode == '5') {
        detail['fixedRowspan'] = 1
      }

      this.$emit('setNoteList', detail.groupName,  detail.categoryName, detail.categoryCode)
      
      // 要件に文言をセット
      // this.$emit('setRequirement')

      // 詳細項目ダイアログ表示
      this.selectProduct(this.itemMasterName(index), detail, index)

      if (detail.categoryCode != '2') {
        this.clickCount++
      } else {
        this.clickCountRlsPlus++
      }
      
    },

    /**
     * カテゴリー名 変更イベント
     * @param detail 詳細
     */
    categoryChange(detail) {
      if (detail.groupName == '') {
        detail.groupName = Math.floor(Math.random()*90000) + 10000
      }
      this.$emit('setNoteList', detail.groupName, detail.categoryName, detail.categoryCode)
    },

    /**
     * 詳細内容表示名変更イベント
     * @param detail 該当列のデータ
     * @param index 番号
     */
    itemNameChanged(detail, index) {

      // 中項目モーダルのデータも変更
      for (let i = index; i >= 0; i--) {
        if (this.value[i].rowspan > 0) {
          if (Object.keys(this.value[i].subItemModalData).length) {
            this.value[i].subItemModalData.display[index-i].name = detail.itemName
            this.value[i].subItemModalData.display[index-i].unitName = detail.itemUnitName
          }
          break;
        }
      }
    },

    /**
     * 詳細内容表示名変更イベント
     * @param detail 該当列のデータ
     * @param index 番号
     */
    categoryNameChanged(detail, index) {

      // 中項目モーダルのデータも変更
      for (let i = index; i >= 0; i--) {
        if (this.value[i].rowspan > 0) {
          if (Object.keys(this.value[i].subItemModalData).length) {
            this.value[i].subItemModalData.display[index-i].unitName2 = detail.categoryUnitName
          }
          break;
        }
      }

      this.$emit('estimateCalculate', detail.groupName, detail.categoryCode)
    },

    /**
     * 発注金額フラグ解除
     * @param gName グループ名
     */
    liftChangePurchasePrice(gName) {
      
      // 発注金額変更フラグ解除
      this.value.forEach((v) => {
        if (v.groupName == gName) {
          v.changePurchasePrice = false
        }
      })
    },

    /**
     * 詳細数量、変更イベント
     * @param detail 該当列のデータ
     * @param index 番号
     */
    itemQtyChanged(detail, index) {

      // 中項目モーダルのデータも変更
      for (let i = index; i >= 0; i--) {
        if (this.value[i].rowspan > 0) {
          if (Object.keys(this.value[i].subItemModalData).length) {
            this.value[i].subItemModalData.changePurchasePrice = false
            this.value[i].subItemModalData.display[index-i].qty = detail.itemQty
          }
          break;
        }
      }
      this.liftChangePurchasePrice(detail.groupName)
      this.$emit('estimateCalculate', detail.groupName, detail.categoryCode)
    },

    /**
     * 金額、変更イベント
     * @param detail 該当列のデータ
     * @param index 番号
     */
    itemModalChanged(detail, index) {

      // 中項目モーダルのデータも変更
      for (let i = index; i >= 0; i--) {
        if (this.value[i].rowspan > 0) {
          if (Object.keys(this.value[i].subItemModalData).length) {
            console.log(this.value[i])
            this.value[i].subItemModalData.changePurchasePrice = false

            // ラウンドプラスの場合
            if (this.value[i].categoryCode == '2') {
              this.value[i].subItemModalData.display[index-i].price2 = detail.itemUnitPrice
              this.value[i].subItemModalData.display[index-i].categoryBgColor = true
            } else {
              this.value[i].subItemModalData.display[index-i].price = detail.itemUnitPrice
              this.value[i].subItemModalData.display[index-i].detailBgColor = true
            }
            
          }
          break;
        }
      }
      this.liftChangePurchasePrice(detail.groupName)
      this.$emit('estimateCalculate', detail.groupName, detail.categoryCode)
    },

    /**
     * 金額、変更イベント
     * @param detail 該当列のデータ
     * @param index 番号
     */
    categoryModalChanged(detail, index) {

      // 中項目モーダルのデータも変更
      for (let i = index; i >= 0; i--) {
        if (this.value[i].rowspan > 0) {
          if (Object.keys(this.value[i].subItemModalData).length) {
            
            this.value[i].subItemModalData.display[index-i].qty2 = detail.categoryQty
          }
          break;
        }
      }

      this.$emit('estimateCalculate', detail.groupName, detail.categoryCode)
    },

    /**
     * 単価表の値段を手動で変更したら、承認申請ボタンを表示するためフラグを立てる
     */
    setPriceChange(i, name) {
      this.estimate.priceChange = true

      if (name == 'detail') {
        this.value[i].detailBgColor = true
      } else if (name == 'category') {
        this.value[i].categoryBgColor = true
      }
    },

    /**
     * 単価表の値段を手動で変更したら、色変更
     */
    setBgColor(target) {

      if (target) {
        return 'bg-yellow-200'
      }
    },

    /**
     * 式内の値を変更したら、単価1の色も変更 2023.04.11仕様変更
     * @param price 単価変更
     * @param icon 式内変更
     * @param code カテゴリコード
     * @param index インデックス
     * @param type タイプ
     */
    setPrice1BgColor(price, icon, code, index, type, detail) {
      // 日常・管理
      if (['3', '4'].includes(code)) {
        if (price) {
          return 'bg-yellow-200'
        } else {
          // modalTabData内で変更しているか確認
          for (let i = index; i >= 0; i--) {
            const v = this.value[i]
            if (v.categoryCode == code && v.rowspan > 0) {
              if (v.subItemModalData && v.subItemModalData.modalTabData) {
                for (let j = 0; j < v.subItemModalData.modalTabData.length; j++) {
                  const m = v.subItemModalData.modalTabData[j]
                  if (type.indexOf(m.id) > -1 && m.data) {
                    if (m.data.iconBgColor == true) {
                      return 'bg-yellow-200'
                    } else {
                      return ''
                    }
                  }
                }
              } else {
                return ''
              }
            }
          }
        }
      // 定期
      } else if (code == '5') {
        if (type == 'fixedCalc') {
          return ''
        }
        if (price) {
          return 'bg-yellow-200'
        } else {
          // modalTabData内で変更しているか確認
          for (let i = index; i >= 0; i--) {
            const v = this.value[i]
            if (v.categoryCode == code && v.rowspan > 0) {
              if (type == '') {
                type = v.type
              }
              if (v.subItemModalData && v.subItemModalData.modalTabData) {
                for (let j = 0; j < v.subItemModalData.modalTabData.length; j++) {
                  const m = v.subItemModalData.modalTabData[j]
                  if ((type.indexOf(m.id) > -1 && m.data)) {
                    if (m.data.iconBgColor == true) {
                      return 'bg-yellow-200'
                    } else {
                      return ''
                    }
                  }
                }
              } else {
                return ''
              }
            }
          }
        }
      // 特別
      } else if (code == '14') {
        if (price) {
          return 'bg-yellow-200'
        } else {
          // modalTabData内でlightingUnitBgColorがtrueか確認
          for (let i = index; i >= 0; i--) {
            const v = this.value[i]
            if (v.categoryCode == code && v.rowspan > 0) {
              if (type == '') {
                type = v.type
              }
              if (v.subItemModalData && v.subItemModalData.display) {
                for (let j = 0; j < v.subItemModalData.display.length; j++) {
                  const m = v.subItemModalData.display[j]
                  if ((m.name == detail.itemName && m.price == detail.itemUnitPrice)) {
                    if (m.lightingUnitBgColor == true) {
                      return 'bg-yellow-200'
                    } else {
                      return ''
                    }
                  }
                }
              } else {
                return ''
              }
            }
          }
        }
      } else {
        if (price || icon) {
          return 'bg-yellow-200'
        }
      }
      return ''
    },

    /**
     * 詳細を選択完了イベント
     */
    async itemSelected(detail, selectedItem, index) {

      // 同じカテゴリーの行を削除（詳細行の追加や削除時の不具合防止のため上書きでなく削除して書き換える）
      let number
      for (let i = index; i < this.value.length; i++) {
        if(this.value[i].groupName !=  detail.groupName) {
          number = i - index
          break
        } else if (i+1 == this.value.length) {
          number = i - index + 1
        }
      }
      if (number) {
        this.value.splice(index, number)
      }

      for (let j = 0; j < selectedItem.display.length; j++) {

        let  emptyEstimateDetail = estimateManager.createEmptyDetail()

        emptyEstimateDetail.categoryCode = detail.categoryCode
        emptyEstimateDetail.categoryName = detail.categoryName
        emptyEstimateDetail.categoryQty = selectedItem.display[j].qty2
        emptyEstimateDetail.categoryUnitName = selectedItem.display[j].unitName2
        emptyEstimateDetail.categoryBgColor = selectedItem.display[j].categoryBgColor
        emptyEstimateDetail.changePurchasePrice = selectedItem.changePurchasePrice
        if (j == 0) {
          emptyEstimateDetail.rowspan = selectedItem.display.length
          emptyEstimateDetail.listForTooltip = (JSON.stringify(selectedItem.toolTip).replace('{', '').replace('}', '').replace(/"/g, '').replace(/:/g, ' : ')).split(',').join('<br>')
          emptyEstimateDetail.iconBgColor = selectedItem.iconBgColor
          emptyEstimateDetail.subItemModalData = selectedItem
          emptyEstimateDetail.modal = true
          emptyEstimateDetail.categoryUnitPrice = selectedItem.display[0].price2

        } else {
          emptyEstimateDetail.rowspan = -1
        }
        emptyEstimateDetail.itemName = selectedItem.display[j].name
        // 月額表示
        if (j == 0 && selectedItem.monthlyFeeFlag && selectedItem.monthlyFee) {
          emptyEstimateDetail.itemName += '　' + '月額：' + selectedItem.monthlyFee.toLocaleString() + '円'
        }
        emptyEstimateDetail.itemQty = String(selectedItem.display[j].qty)
        emptyEstimateDetail.itemUnitName = selectedItem.display[j].unitName
        emptyEstimateDetail.type = selectedItem.display[j].type

        if (detail.categoryCode != '2') {
          emptyEstimateDetail.detailBgColor = selectedItem.display[j].detailBgColor
        }

        // 定期清掃、スポットの場合、基本単価変更されたら、単価フラグを立てる
        if (detail.categoryCode == '5' || detail.categoryCode == '28') {
          if (selectedItem.display[j].priceKindBgColor) {
            emptyEstimateDetail.detailBgColor = true
          }
        }

        // 粗大ごみ、物品販売の場合、基本単価orオーナー契約or竣工係数が変更されたら、単価フラグを立てる
        if (detail.categoryCode == '24' || detail.categoryCode == '25') {
          if (selectedItem.display[j].priceKindBgColor || selectedItem.display[j].ownerBgColor || selectedItem.display[j].finishPointBgColor) {
            emptyEstimateDetail.detailBgColor = true
          }
        }

        // ラウンドトラッシュ
        if (detail.categoryCode == '8') {
          emptyEstimateDetail.trashTimesBgColor = selectedItem.display[j].trashTimesBgColor
        }

        // ラウンドプラスの場合
        if (detail.categoryCode == '2') {
          // カテゴリー名
          emptyEstimateDetail.categoryName = selectedItem.rlsPlus
          // 数量rowspan
          emptyEstimateDetail.plusRowspan = selectedItem.display[j].plusRowspan
          // 明細単価を表示するか
          emptyEstimateDetail.isUnitPrice = selectedItem.isUnitPrice

          // 見積りに明細単価を表示する場合
          if (selectedItem.isUnitPrice) {
            console.log(selectedItem.display[j])
            emptyEstimateDetail.itemUnitPrice = String(selectedItem.display[j].price)

            // 式の中の金額を変更した場合
            emptyEstimateDetail.iconBgColor2 = selectedItem.display[j].iconBgColor2

            // 作業範囲と定期は結合する
            if (selectedItem.display[j].type == 'fixedName') {
              const range = selectedItem.display.filter((d) => {
                return d.type == 'fixedRange'
              })
              if (range && range.length) {
                emptyEstimateDetail.fixedRowspan = range.length + 1
              } else {
                emptyEstimateDetail.fixedRowspan = 1
              }
            } else if (selectedItem.display[j].type == 'fixedRange') {
              emptyEstimateDetail.fixedRowspan = -1
            }
          } else {
            if (j == 0) {
              // 金額
              emptyEstimateDetail.itemUnitPrice = selectedItem.display[0].price2
            } else {
              // 金額
              emptyEstimateDetail.itemUnitPrice = '0'
            }
          }
        
        // 排水管清掃
        } else if (detail.categoryCode == '11') {
          emptyEstimateDetail.itemUnitPrice = String(selectedItem.display[j].price)

        } else {
          emptyEstimateDetail.itemUnitPrice = String(selectedItem.display[j].price)
        }
        emptyEstimateDetail.groupName = detail.groupName

        // 定期
        if (detail.categoryCode == '5') {
          if (selectedItem.display[j].fixedRowspan) {
            emptyEstimateDetail['fixedRowspan'] = selectedItem.display[j].fixedRowspan
            if (emptyEstimateDetail.categoryBgColor) {
              emptyEstimateDetail.categoryUnitPrice = selectedItem.display[j].price2
            }
          } else {
            emptyEstimateDetail['fixedRowspan'] = -1
          }
        }

        // 特別清掃 新仕様の場合
        if (detail.categoryCode == '14' && selectedItem.newData) {
          emptyEstimateDetail.newData = selectedItem.newData
        }

        // 挿入
        this.value.splice(index+j, 0, emptyEstimateDetail)
      }
      // 計算
      this.$emit('estimateCalculate', null, null)
      // 特記事項をセット
      this.$emit('setNoteText', detail.groupName, selectedItem.noteHTML)
      // 発注明細挿入
      this.$emit('insertPurchase', selectedItem.purchase)
      // 手数料明細挿入
      this.$emit('insertCommission', selectedItem.commission)
      // メインとの違いを見つける
      this.$emit('getDiffDetails')
      // 要件に文言をセット
      // this.$emit('setRequirement')
    },

    detailHistorySelected(detail, selectedDetail) {
      // 見積り
      detail.itemName = selectedDetail.itemName
      detail.unitPrice = selectedDetail.unitPrice
    },

    /**
     * プラスボタンイベント
     */
    addRow() {
      const emptyEstimateDetail = estimateManager.createEmptyDetail()

      this.value.push(emptyEstimateDetail)
      emptyEstimateDetail.index = this.value.length - 1
      emptyEstimateDetail.groupName = String(this.value.length - 1)
      // 要件に文言をセット
      // this.$emit('setRequirement')
    },

    /**
     * 見積明細 全件削除
     */
    async deleteAll() {
      const dialogResult = await dialogs.showWarningConfirmDialog('明細の削除', 'すべての明細を削除します。よろしいですか？')
      if (dialogResult === 'YES') {
        this.value.splice(0)
        this.addRow()
        // 特記事項も削除
        this.estimate.noteList.splice(0)
        this.estimate.noteList = [{
          categoryName: '',
          groupName: '',
          noteHTML: ''
        }]
      }
      // 再計算
      this.$emit('estimateCalculate', null, null)
    },

    /**
     * 削除ボタンイベント
     */
    deleteClicked(index, deleteLength) {
      // 特記事項も削除
      let gName = this.value[index].groupName
      this.$emit('deleteNoteList', gName)

      // 見積り削除
      this.value.splice(index, deleteLength)
      if (!this.value.length) {
        this.addRow()
      }

      // 再計算
      this.$emit('estimateCalculate', null, null)
      
      // メインとの違いを見つける
      this.$emit('getDiffDetails')
      // 要件に文言をセット
      // this.$emit('setRequirement')
    },

    /**
     * 並べ替えイベント
     */
    detailOrderChanged(arg) {
      let oldIndex
      let newIndex
      let groupName
      let group = []
      let element

      if (arg.moved) {
        oldIndex = arg.moved.oldIndex
        newIndex = arg.moved.newIndex
        groupName = arg.moved.element.groupName
        element = arg.moved.element
      } else {
        return
      }

      // 同じグループの間に割り込もうとしたら、移動をキャンセル
      if (newIndex != 0 && newIndex != this.value.length-1 && this.value[newIndex-1].groupName == this.value[newIndex+1].groupName) {
        this.value.splice(newIndex, 1)
        this.value.splice(oldIndex, 0, element)
        return
      }

      for (let i = 0; i < this.value.length; i++) {
        if (i != newIndex && groupName == this.value[i].groupName) {
          group.push(this.value[i])
        }
      }

      if (group.length) {
        // 新しい位置に挿入
        this.value.splice(newIndex+1, 0 , ...group)
        // 前の位置から削除
        if (oldIndex > newIndex) {
          this.value.splice(oldIndex+1+group.length, group.length)
        } else {
          this.value.splice(oldIndex, group.length)
        }
        
      }

      // 特記事項も並び替え
      // 見積りの行からグループ名のみの配列を作成
      let groupArr = []
      let list = []
      this.estimate.details.forEach((d) => {
        if (!groupArr.includes(d.groupName)) {
          groupArr.push(d.groupName)
          for (let i = 0; i < this.estimate.noteList.length; i++) {
            const n = this.estimate.noteList[i]
            if (d.groupName == n.groupName) {
              list.push(n)
            }
          }
        }
      })
      this.estimate.noteList = list

      // 発注・手数料明細も並び替え
      // 見積りの行からグループ名のみの配列を作成
      let groupArr2 = []
      let list2 = []
      let list3 = []
      this.estimate.details.forEach((d) => {
        if (!groupArr2.includes(d.groupName)) {
          groupArr2.push(d.groupName)
          for (let i = 0; i < this.estimate.purchase.length; i++) {
            const p = this.estimate.purchase[i]
            if (d.groupName == p.groupName) {
              list2.push(p)
            }
          }
          for (let j = 0; j < this.estimate.commission.length; j++) {
            const c = this.estimate.commission[j]
            if (d.groupName == c.groupName) {
              list3.push(c)
            }
          }
        }
      })
      this.estimate.purchase = list2
      this.estimate.commission = list3
    },

    refresh() {
      this.renderKey++
    },

    nextField(e) {
      if (FEATURES.ESTIMATION.DETAIL_INPUT_ENTER_KEY_TO_MOVE) {
        console.log(e)
        // setTimeout(() => {
          const inputs = Array.from(this.$refs.tableBody.$el.querySelectorAll('input:enabled')) //.querySelector('input[type=text]')
          const index = inputs.findIndex((i) => {
            return e.target === i
          })
          console.log(`next field : [${index + 1}]`)
          const next = inputs[index + 1]
          if (next) {
            next.focus()
          }          
        // }, 50)
      }
    },

    // handleRemarksEnterKey(e) {
    //   setTimeout(() => {
    //     this.nextField(e)
    //   })
    // },

    /**
     * モーダルに渡す内容、コールバック
     * 
     */
    selectProduct(detailInfo, detail, index) {
      if (detail.categoryCode != '2') {
        // 対象行のセル結合している行全てのデータを取得
        let group = []
        group = this.value.filter((v) => {
          return detail.groupName == v.groupName
        })

        this.dataSelectorParams = {
          categoryCode: detail.categoryCode,
          categoryName: detail.categoryName,
          modalData: detail.subItemModalData,
          group: group,
          index:index,
          selectCallback: (selectedProduct) => {
            this.dataSelectorShow = false
            detailInfo.onSelected(detail, selectedProduct, index)
          }
        }
        // 発注明細
        this.getPurchaseData(detail)
        // 手数料明細
        this.getCommissionData(detail)
        this.dataSelectorShow = true
        this.clickCount++

      // ラウンドプラス
      } else {
        this.dataSelectorParams = {
          categoryCode: detail.categoryCode,
          modalData: detail.subItemModalData,
          row: detail,
          index:index,
          selectCallback: (selectedProduct) => {
            this.dataSelectorShowRlsPlus = false
            detailInfo.onSelected(detail, selectedProduct, index)
          }
        }
        // 発注明細
        this.getPurchaseData(detail)
        // 手数料明細
        this.getCommissionData(detail)
        this.dataSelectorShowRlsPlus = true
        this.clickCountRlsPlus++
      }
    },

    /**
     * 詳細モーダルに渡す発注明細を取得
     * @param detail 対象見積り
     */
    getPurchaseData(detail) {
      this.purchaseData = []
      for (let i = 0; i < this.estimate.purchase.length; i++) {
        const p = this.estimate.purchase[i];
        if (p.groupName == detail.groupName) {
          this.purchaseData.push(p)
        }
      }
    },
    
    /**
     * 詳細モーダルに渡す手数料明細を取得
     * @param detail 対象見積り
     */
    getCommissionData(detail) {
      this.commissionData = {}
      for (let i = 0; i < this.estimate.commission.length; i++) {
        const c = this.estimate.commission[i]
        if (c.groupName == detail.groupName) {
          this.commissionData = c
          break
        }
      }
    },

    // 行No表示
    setNo(index) {
      let No = 0
      for (let i = 0; i < index; i++) {
        if (this.value[i].rowspan < 0) {
          No += this.value[i].rowspan
        }
      }
      return index + No
    },

    /**
     * カテゴリー金額変更イベント
     */
    changeCategoryPrice(detail, index) {

      // 中項目モーダルのデータも変更
      for (let i = index; i >= 0; i--) {
        if (this.value[i].rowspan > 0) {
          if (Object.keys(this.value[i].subItemModalData).length) {
            this.value[i].subItemModalData.display[index-i].price2 = detail.categoryUnitPrice
            this.value[i].subItemModalData.display[index-i].categoryBgColor = true
          }
          break;
        }
      }
      this.setPriceChange(index, 'category')
      this.$emit('estimateCalculate', detail.groupName, detail.categoryCode)
    },

    /**
     * 詳細モーダルの単価が手動で変更されたらアイコンの背景色を変える
     * @param iconBgColor フラグ
     */
    setIconBgColor(iconBgColor) {
      if (iconBgColor) {
        return 'bg-yellow-200'
      }
    },

    /**
     * 詳細数量 rowspanセット
     * @param detail 対象行
     */
    setRowspanQty(detail) {
      if (!detail.isUnitPrice && detail.plusRowspan) {
        return detail.plusRowspan
      } else {
        return 1
      }
    },

    /**
     * rowspanセット
     * @param detail 対象行
     */
    setRowspan(detail) {
      if (detail.categoryCode == '5') {
        return detail.fixedRowspan
      } else {
        return detail.rowspan
      }
    },

    /**
     * 数量2と単価2の並び替え
     */
    columnDrag(num) {
      if (num == 1) {
        this.estimate.columnDragFlagOne = !this.estimate.columnDragFlagOne
      } else {
        this.estimate.columnDragFlag = !this.estimate.columnDragFlag
      }
    }
  },

  computed: {
    isModileDevice() {
      return utils.deviceInfo.isMobile
    },

    formatNumber() {
      return (val) => {
        return utils.formatNumber(val, true)
      }
    },

    totalAmount() {
      return this.estimate.totalAmount
    },

    itemMasterName() {
      const self = this
      return (index) => {
        const name = self.value[index].categoryName
        // カテゴリーコードがあり、カテゴリーに対し1行目の詳細のみにアイコンを表示
        if (name && name != '' && (index == 0 || self.value[index].rowspan > 0)) {
          return {
            // アイコン表示
            searchDialog: true,
            // 詳細決定後
            onSelected: this.itemSelected
          }
        } else {
          return {
            onSelected: () => {}
          }
        }
      }
    },

    /**
     * 見積明細_カテゴリー_ドロップダウン
     */
    categorySelectionItems() {
      const list = this.$store.getters.estimateCategoryList
      return list
    },

    /**
     * カラム名
     */
    columns() {
      if (this.estimate.columnDragFlag) {
        return [
          {title: 'No.', rowspan: 2, colspan: 1, class: 'bg-cate'},
          {title: 'カテゴリー', rowspan: 2, colspan: 1, class: 'bg-cate'},
          {title: '詳細', rowspan: 1, colspan: 4, class: 'bg-detail border-bt'},
          {title: '数量', rowspan: 2, colspan: 2, class: 'bg-cate'},
          {title: '単価', rowspan: 2, colspan: 1, class: 'bg-cate'},
          {title: '金額', rowspan: 2, colspan: 1, class: 'bg-cate'}
        ]
      } else {
        return [
          {title: 'No.', rowspan: 2, colspan: 1, class: 'bg-cate'},
          {title: 'カテゴリー', rowspan: 2, colspan: 1, class: 'bg-cate'},
          {title: '詳細', rowspan: 1, colspan: 4, class: 'bg-detail border-bt'},
          {title: '単価', rowspan: 2, colspan: 1, class: 'bg-cate'},
          {title: '数量', rowspan: 2, colspan: 2, class: 'bg-cate'},
          {title: '金額', rowspan: 2, colspan: 1, class: 'bg-cate'}
        ]
      }
    },
    
    /**
     * 詳細のカラム
     */
    itemColumns() {
      if (this.estimate.columnDragFlagOne) {
        return [
          {title: '内容', colspan: 1, class: 'w-300'},
          {title: '単価', colspan: 1, class: 'w-170'},
          {title: '数量', colspan: 2, class: 'w-160'},
        ]
      } else {
        return [
          {title: '内容', colspan: 1, class: 'w-300'},
          {title: '数量', colspan: 2, class: 'w-160'},
          {title: '単価', colspan: 1, class: 'w-170'}
        ]
      }
    },

    /**
     * 数字フォーマット
     */
    formatValue() {
      return (val) => {
        if (val && val != '') {
          return Number(val).toLocaleString()
        } else {
            return ''
        }
      }
    },

    isIcon() {
      return (index) => {
        return this.itemMasterName(index).searchDialog
      }
    }
  }
}
</script>
<style scoped>
td.col-no {
  width: 70px;  
}
td.col-contract-class {
  width: 130px;
}
td.col-qty {
  width: 60px;
  text-align: center;
  height: 35px;
}
td.col-unit-name {
  width: 70px;
  height: 35px;
}
td.col-period-unit {
  width: 60px;
}
td.col-unit-price {
  width: 130px;
  height: 35px;

}
td.col-amount {
  width: 140px;
}
td.col-actions {
  width: 40px;
}
th.border-bt {
  border-bottom-width: 1px;
}
th.border-left {
  border-left-width: 1px;
}
td.border-left {
  border-left-width: 1px;
}
.itemname-width {
  width: 95%;
}
.w-300 {
  min-width: 300px;
}
.w-200 {
  min-width: 200px;
}
.w-160 {
  min-width: 160px;
}
.w-170 {
  min-width: 170px;
}
.bg-detail {
  background: #f8feff;
}
.bg-cate {
  background: #f0fbfd;
}

@media screen and (max-width: 1200px) {
  .detail {
    overflow: auto;
  }
}
</style>