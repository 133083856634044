<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div class="main">
      <!-- メール送信画面 -->
      <div v-if="isMail">
        <!-- 得意先名 -->
        <div class="text-lg font-bold text-blue-600 mb-5">
          {{ clientName }}
        </div>
        <!-- メールアドレス -->
        <div class="mb-3">
          <div class="flex justify-between">
            <div class="text-xs font-bold mt-auto">メールアドレス
              <span class="-top-0.5 ml-1 text-red-500"> * </span>
            </div>
            <PrimaryButton
              text="アドレス追加"
              @click="mailAdd()"
              class="h-7 bg-blue-50 text-blue-900 border-blue-900 m-1"
            >
            </PrimaryButton>

          </div>
          <div v-for="(m, i) in mail" :key="i">
            <div class="flex">
              <WmsTextInput
                name="email" 
                class="w-full"
                :error="!m||m==''"
                v-model="mail[i]"
              />
              <div class="m-auto ml-2">
                <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" @click="mailDelete(i)"/>
              </div>
            </div>
          </div>
        </div>
        <!-- 件名 -->
        <div class="mb-3">
          <WmsTextInput
            name="mailTitle" 
            caption="件名" 
            :required="true"
            :error="mailTitle==''"
            v-model="mailTitle"
          />
        </div>
        <!-- 本文 -->
        <div class="mb-3">
          <WmsTextInput 
            name="mailText" 
            caption="本文"
            :multiline="true"
            :rows="15"
            :required="true"
            :error="mailText==''"
            v-model="mailText"
          />
        </div>
      </div>

      <!-- FAX用紙出力画面 -->
      <div v-else>

      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import { TrashIcon } from '@vue-hero-icons/solid'
import * as moment from 'moment'
// import * as backend from '@libs/backend'
export default {
  components: {
    Modal,
    PrimaryButton,
    WmsTextInput,
    TrashIcon
  },

	props: {
		open: {
			type: Boolean,
			default: false
		},
    // メール選択の場合
		isMail: {
			type: Boolean,
			default: false
		},
    // FAX番号
    clientFax: {
      type: String,
      default: null
    },
    // 得意先名
		clientName: {
			type: String,
			default: null
		},
    // メインデータ
    requestPost: {
      type: Object
    }
	},

  data() {
    return {
      // ローディング
      loading: false,
      // メールアドレス
      mail: [],
      // メールタイトル
      mailTitle: null,
      // メール本文
      mailText: null,
      // 未来か否か
      isFuture: false,
      // 区分
      type: '入社'
    }
  },

  computed: {
  },

  watch: {
    async open() {
      if (this.open) {
        await this.getData()
      }
    }
  },

  methods: {
    /**
     * 得意先取得
     */
    async getData() {
      this.loading = true
      // const result = await backend.searchData('akbsTable/getSiteList', this.filter)
      // if (result && result.data.data && result.data.data.length) {
      //   this.list = result.data.data
      // } else {
      //   this.list = []
      // }

      let date = null
      // 入社か、退職を判断
      if (this.requestPost.employmentClass && this.requestPost.employmentClass[0]) {
        if (this.requestPost.employmentClass[0].indexOf('退職') > -1) {
          this.type = '退職'
          date = Number(this.requestPost.employmentRetirementDate.replace(/-/g, ''))
        } else {
          this.type = '入社'
          date = Number(this.requestPost.employmentHireDate.replace(/-/g, ''))
        }
        const today = Number(moment().format('YYYYMMDD'))
        // 対象日が過去の場合
        if (date < today) {
          this.isFuture = false
        } else {
          this.isFuture = true
        }
      }

      if (!this.mail.length) {
        this.mail = ['']
      }
      this.loading = false
    },

    /**
     * メールアドレス追加
     */
    mailAdd() {
      this.mail.push('')
    },

    /**
     * 閉じる
     */
    closed() {
      // ローディング
      this.loading = false
    }
  }
}
</script>
<style scoped>
.main {
  min-width: 700px;
}
</style>