import * as utils from '@libs/utils'
import { PRIVATE_REQUEST_AUTH } from '@/config'

const REQUESTPOST_TASKS_TYPE = 'apollo_request_post'
// const SUB_TASKS_TYPE = 'task_request'
const ORDER_TASKS_TYPE = 'order'

export const RequestPostSchema = {
  category2: [],
  category: [],
  requestNo: '',
  lastRequestNo: '',
  clientName: '',
  clientDepartmentName: '',
  clientAddress: '',
  clientAddress2:'',
  clientPersonName: '',
  clientMail: '',
  inquiry_title: '',
  note: '',
  commonFile: {},
  addFileBtn: [],
  commonFile2: {},
  commonFile3: {},
  commonFile4: {},
  commonFile5: {},
  reason_note: '',
  toDepartment: '',
  toDepartmentId: '',
  helpBtn: '',
  mailingStatus: '',
  labelSeminarInfoGroup: '',
  toStaffName: '',
  toStaffId: '',
  byUser: '',
  byUserId: '',
  requestDate: '',
  limitDate: '',
  newNowFlag: [],
  newClass: [],
  estimationCategory: [],
  estimationCleanCategory: [],
  estimationInspectCategory: [],
  estimationOtherCategory: [],
  estimationBasicLabel: '',
  buildingName: '',
  buildingAddressLabel: '',
  stairs: '',
  basement: '',
  households: '',
  estimationReason: '',
  availablePowerFlag: [],
  parkingFlag: [],
  availableToiletFlag: [],
  apartmentType: [],
  estimationRoundLabel: '',
  cleanCount: [],
  dustRemoval: [],
  dustCount: [],
  rlsToiletCleaning: [],
  rlsToiletTimes: '',
  rlsToiletPlace: '',
  estimationRoundPlusLabel: '',
  plusCleanCount: [],
  plusDustRemoval: [],
  plusDustCount: [],
  plusToiletCleaning: [],
  plusToiletCleaningNormal: [],
  plusToiletTimes: '',
  plusToiletTimesNormal: '',
  plusToiletPlace: '',
  plusToiletPlaceNormal: '',
  residenceType: [],
  estimationEverydayLabel: '',
  everydayCleans: [],
  managerWorkLabel: '',
  managerWorks: [],
  regularCleanLabel: '',
  regularCleans: [],
  regularCleanImage: {},
  regularCleanImage2: {},
  regularCleanImage3: {},
  regularCleanWorkWay: '',
  glassCleanLabel: '',
  glassCleanCount: '',
  glassCleanImage: {},
  glassCleanImage2: {},
  glassCleanImage3: {},
  glassCleanWorkWay: [],
  plantingLabel: '',
  plantingCount: '',
  plantingPlaceFile: {},
  plantingPlaceFile2: {},
  plantingPlaceFile3: {},
  plantingReportFile: {},
  plantingReportFile2: {},
  plantingReportFile3: {},
  specialCleanLabel: '',
  specialCleans: [],
  specialCleanImage: {},
  specialCleanImage2: {},
  specialCleanImage3: {},
  roundSubLabel: '',
  roundSubs: [],
  fireInspectLabel: '',
  fireInspectReport: {},
  fireInspectReport2: {},
  fireInspectReport3: {},
  pressPumpInspectLabel: '',
  pressPumpInspectReport: {},
  pressPumpInspectReport2: {},
  pressPumpInspectReport3: {},
  constructionEquipmentInspectLabel: '',
  constructionEquipmentInspectReport: {},
  constructionEquipmentInspectReport2: {},
  constructionEquipmentInspectReport3: {},
  specificInspectLabel: '',
  specificInspectReport: {},
  specificInspectReport2: {},
  specificInspectReport3: {},
  estimationWaterTankLabel: '',
  waterTankCapacity: '',
  elevatedWaterTankFlag: '',
  waterQualityInspectFlag: '',
  waterPompInspectFlag: '',
  waterTankReport: {},
  waterTankReport2: {},
  waterTankReport3: {},
  estimationDorainPipeLabel: '',
  dorainPipeCleanCount: '',
  dorainPipeAge: '',
  dorainPipeCleanResults: '',
  dorainPipeHolidyFlag: '',
  dorainPipeMultiFlag: '',
  estimationCommonAreaLabel: '',
  commonAreaInspectCount: '',
  commonAreaReport: {},
  commonAreaReport2: {},
  commonAreaReport3: {},
  evInspectLabel: '',
  evInspectContent: [],
  evInspectFile: {},
  evInspectFile2: {},
  evInspectFile3: {},
  evInspectMaker: '',
  evInspectMakeYear: '',
  evQty: '',
  evUnitName: '',
  createDate: '',
  postalCode: '',
  state: '',
  city: '',
  street: '',
  building: '',
  otherWorks: [],
  roundServiceMemo: '',
  roundPlusMemo: '',
  everydayCleanMemo: '',
  managerWorkMemo: '',
  regularCleanMemo: '',
  glassCleanMemo: '',
  estimationDorainPipeMemo: '',
  estimationWaterTankMemo: '',
  plantingMemo: '',
  specialCleanMemo: '',
  roundSubMemo: '',
  estimationMRSMemo: '',
  temporarySaveFlag: false,
  orderNo: '',
  telephoneNumber: '',
  dentatsuCategory: [],
  notNewEnter: true,
  employeeName: '',
  employmentClass: [],
  employmentName: '',
  employmentKana: '',
  employmentStaffNumber: '',
  employmentBirthDay: '',
  employmentAge: '',
  employmentSex: [],
  employmentPostCode: '',
  employmentAddress1: '',
  employmentAddress2: '',
  employmentTel: '',
  employmentMobile: '',
  employmentEmergencyContactKana: '',
  employmentEmergencyContact: '',
  employmentEmergencyAddress: '',
  employmentEmergencyPhoneNumber: '',
  employmentEmergencyRelationship: '',
  employmentHourlyWage: '',
  employmentClosingDate: [],
  employmentTrainType: [],
  employmentTrainExpenses: '',
  employmentBusType: [],
  employmentBusExpenses: '',
  employmentParkingType: [],
  employmentParkingExpenses: '',
  employmentBank: '',
  employmentBankBranch: '',
  employmentBankAccountNumber: '',
  employmentBankAccountName: '',
  employmentAppliedWork: [{
    days: [],
    startTime: '',
    endTime: '',
    startBreakTime: '',
    endBreakTime: ''
  }],
  employmentArea: '',
  employmentCustomer: '',
  clientFax: '',
  employmentBuildingName: '',
  employmentSiteCode: '',
  employmentSiteAddress: '',
  employmentChangedDate: '',
  employmentWorkTask: [],
  employmentHolidayType: [],
  employmentTrashRemoval: [],
  employmentHireDate: '',
  employmentRetirementDate: '',
  employmentMaterial: [],
  employmentDocument: {},
  employmentRemarks:'',
  interviewsInputDay: '',
  interviewsKana: '',
  interviewsName: '',
  interviewsPostCode: '',
  interviewsAddress1: '',
  interviewsAddress2: '',
  interviewsTel: '',
  interviewsMobile: '',
  interviewsSex: [],
  interviewsBirthDay: '',
  interviewsAge: '',
  interviewsNearestStationFromHome: '',
  interviewsFamilyMembers:[{
    interviewsFamilyRelationship: '',
    interviewsFamilyAddress: '',
    interviewsFamilyName:'',
    interviewsFamilyPhoneNumber: ''
    }],
  interviewsEmergencyContactKana: '',
  interviewsEmergencyContact: '',
  interviewsEmergencyAddress: '',
  interviewsEmergencyPhoneNumber: '',
  interviewsEmergencyRelationship: '',
  interviewsHourlyWage: '',
  interviewsAppliedWorkLocationAddress: '',
  interviewsSiteCode: '',
  interviewsSiteAddress: '',
  interviewsWorkTask: [],
  interviewsHolidayType: [],
  interviewsTrashRemoval: [],
  interviewsArea: '',
  interviewsAppliedWork:[{
    days: [],
    startTime: '',
    endTime: '',
    startBreakTime: '',
    endBreakTime: ''
  }],
  interviewsOtherPropertyConsultation: [],
  interviewsDesiredDateAndTime: '',
  interviewsSubstituteWork: [],
  interviewsPossibleDateAndTime: '',
  interviewsEarliestStartDate: '',
  interviewsSmoking: [],
  interviewsSmokingBan: [],
  interviewsAlcoholConsumption: [],
  interviewsPersonalHygiene: [],
  interviewsTransferAfterProbation: [],
  interviewsMonthlyLeaveForFamily: [],
  interviewsReasonForLeave: '',
  interviewsResignationNotice: [],
  interviewsContactBeforeLeave: [],
  interviewsCleaningExperience: [],
  interviewsChronicDisease: [],
  interviewsApplicableDiseases: [],
  interviewsOtherConditions: '',
  interviewsCurrentMedication: [],
  interviewsMedicationDetails: '',
  interviewsHealthConcerns: [],
  interviewsHealthConcernsDetails: '',
  interviewsCognitiveTestScore: '',
  interviewsMaterial: [],
  interviewsCognitiveRes: {},
  interviewsDocument: {},
  interviewsInterviewNotes: '',
  interviewsResult: [],
  interviewsCommonFileList: [1]
}

/** 
 * 添付ファイル項目の初期値
 */
export const AttachmentSchema = {
  originalName: '',
  url: '',
  content: ''
}

/** 
 *  リクエストポスト 取得
 */
export async function getRequestPost(context, _id) {
  const searchCondition = {
    searchCondition: {
      type: REQUESTPOST_TASKS_TYPE,
      _id
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const allocation = responseData[0] || null
    return allocation
  } catch (error) {
    console.log('getRequestPost error !!!')
    console.log(error)
    return null
  }
}

/**
 * リクエストポスト _id 取得
 * @param {*} context 
 * @param {*} _id 
 * @returns 
 */

export async function getRequest(context, _id) {
  const searchCondition = {
    searchCondition: {
      type: REQUESTPOST_TASKS_TYPE,
      _id
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const allocation = responseData[0] || null
    return allocation
  } catch (error) {
    console.log('getRequest error !!!')
    console.log(error)
    return null
  }
}

/**
 * 雇い入れ用面接関連 取得
 * @returns 
 */
export async function getInterviersForEmployment(context, requestNo, interviewsName, clientName, periodCreate, toStaffName) {

  const searchCondition = {
    searchCondition: {
      type: REQUESTPOST_TASKS_TYPE,
      status: { $ne: "remove" },
      category: '面接関連',
      interviewsResult: '採用',
      isEmployment: { $ne: true }
    },
    sort: {
      requestNo: -1
    }
  }

  // 作成日期間 条件指定
  if (periodCreate) {
    searchCondition.searchCondition['createDate'] = {}
    if (periodCreate.start) {
      searchCondition.searchCondition['createDate'].$gte = periodCreate.start
    }
    if (periodCreate.end) { 
      let end = new Date(periodCreate.end);
      // 年、月、日を取得
      let year = end.getFullYear();
      let month = String(end.getMonth() + 1).padStart(2, "0"); 
      let day = String(end.getDate() + 1).padStart(2, "0");
      // "yyyy-mm-dd" の形式に整形
      let formattedDate = `${year}-${month}-${day}`;
      searchCondition.searchCondition['createDate'].$lt = formattedDate;
    }
    if (Object.keys(searchCondition.searchCondition['createDate']).length === 0) {
      delete searchCondition.searchCondition['createDate']
    }
  }

  // お問い合わせ番号 条件指定
  if (requestNo && requestNo.length > 0) searchCondition.searchCondition['requestNo'] = { $regex: requestNo, $options: 'i'}

  // 面接者名 条件指定
  if (interviewsName && interviewsName.length > 0) searchCondition.searchCondition['interviewsName'] = { $regex: interviewsName, $options: 'i'}

  // 得意先名 条件指定
  if (clientName && clientName.length > 0) searchCondition.searchCondition['clientName'] = { $regex: clientName, $options: 'i'}
  
  // 担当者 条件指定
  if (toStaffName && toStaffName.length > 0) searchCondition.searchCondition['toStaffName'] = { $regex: toStaffName, $options: 'i'}

  try {
    console.log(searchCondition)
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const interviewsData = responseData || []
    interviewsData.forEach((e) => {
      if (e.totalAmount == '') {
        e.totalAmount = '0'
      }
    })
    return interviewsData
  } catch (error) {
    console.log('getInterviewsForEmployment error !!!')
    console.log(error)
    return []
  }
}

/** 
 * リクエストポスト 登録
 */
export async function register(context, rp, cwCooperation) {
  try {
    // お問い合わせ番号採番（雇い入れ以外もしくは、雇い入れだがお問い合わせNoがないもの）
    if (rp.category.indexOf('雇い入れ') == -1 || (rp.category.indexOf('雇い入れ') != -1 && !rp.requestNo)){
      const no = await createNo(context, rp)
      rp.requestNo = no
    }

    const registrationData = createRegistrationData(rp)
    console.log('registrationData : ')
    console.log(registrationData)

    const q = {}
    if (cwCooperation === true) {
      q.cwCooperation = 'cw-on'
    } else if (cwCooperation === false) {
      q.cwCooperation = 'cw-off'
    }

    const result = await context.$pigeon.registerTask(REQUESTPOST_TASKS_TYPE, registrationData, q)

    return { id: result._id, requestNo: rp.requestNo }
  } catch (error) {
    console.log('register error !!!')
    console.log(error)
    return false
  }
}

/**
 * 更新
 * @param {*} context 
 * @param {*} rp 
 * @param {*} cwCooperation 
 * @returns 
 */
export async function update(context, rp, cwCooperation) {
  try {
    const registrationData = createRegistrationData(rp)
    registrationData.type = REQUESTPOST_TASKS_TYPE
    console.log('registrationData : ')
    console.log(registrationData)

    const q = {}
    if (cwCooperation === true) {
      q.cwCooperation = 'cw-on'
    } else if (cwCooperation === false) {
      q.cwCooperation = 'cw-off'
    }

    await context.$pigeon.updateTask(registrationData._id, registrationData, q)

    return { id: registrationData._id, requestNo: registrationData.requestNo }
  } catch (error) {
    console.log('update error !!!')
    console.log(error)
    return false
  }
}

/**
 * 新規リクエストポスト生成
 * @returns 
 */
export function create() {
  const limitDate = utils.getToday()
  const requestDate = utils.getToday()
  const newRequestPost = { ...utils.clone(RequestPostSchema), limitDate, requestDate }

  newRequestPost.commonFile = createEmptyAttachment()
  newRequestPost.commonFile2 = createEmptyAttachment()
  newRequestPost.commonFile3 = createEmptyAttachment()
  newRequestPost.commonFile4 = createEmptyAttachment()
  newRequestPost.commonFile5 = createEmptyAttachment()
  newRequestPost.regularCleanImage = createEmptyAttachment()
  newRequestPost.regularCleanImage2 = createEmptyAttachment()
  newRequestPost.regularCleanImage3 = createEmptyAttachment()
  newRequestPost.glassCleanImage = createEmptyAttachment()
  newRequestPost.glassCleanImage2 = createEmptyAttachment()
  newRequestPost.glassCleanImage3 = createEmptyAttachment()
  newRequestPost.plantingPlaceFile = createEmptyAttachment()
  newRequestPost.plantingPlaceFile2 = createEmptyAttachment()
  newRequestPost.plantingPlaceFile3 = createEmptyAttachment()
  newRequestPost.plantingReportFile = createEmptyAttachment()
  newRequestPost.plantingReportFile2 = createEmptyAttachment()
  newRequestPost.plantingReportFile3 = createEmptyAttachment()
  newRequestPost.specialCleanImage = createEmptyAttachment()
  newRequestPost.specialCleanImage2 = createEmptyAttachment()
  newRequestPost.specialCleanImage3 = createEmptyAttachment()
  newRequestPost.fireInspectReport = createEmptyAttachment()
  newRequestPost.fireInspectReport2 = createEmptyAttachment()
  newRequestPost.fireInspectReport3 = createEmptyAttachment()
  newRequestPost.pressPumpInspectReport = createEmptyAttachment()
  newRequestPost.pressPumpInspectReport2 = createEmptyAttachment()
  newRequestPost.pressPumpInspectReport3 = createEmptyAttachment()
  newRequestPost.constructionEquipmentInspectReport = createEmptyAttachment()
  newRequestPost.constructionEquipmentInspectReport2 = createEmptyAttachment()
  newRequestPost.constructionEquipmentInspectReport3 = createEmptyAttachment()
  newRequestPost.specificInspectReport = createEmptyAttachment()
  newRequestPost.specificInspectReport2 = createEmptyAttachment()
  newRequestPost.specificInspectReport3 = createEmptyAttachment()
  newRequestPost.waterTankReport = createEmptyAttachment()
  newRequestPost.waterTankReport2 = createEmptyAttachment()
  newRequestPost.waterTankReport3 = createEmptyAttachment()
  newRequestPost.commonAreaReport = createEmptyAttachment()
  newRequestPost.commonAreaReport2 = createEmptyAttachment()
  newRequestPost.commonAreaReport3 = createEmptyAttachment()
  newRequestPost.evInspectFile = createEmptyAttachment()
  newRequestPost.evInspectFile2 = createEmptyAttachment()
  newRequestPost.evInspectFile3 = createEmptyAttachment()
  newRequestPost.employmentDocument = createEmptyAttachment()
  newRequestPost.interviewsCognitiveRes = createEmptyAttachment()
  newRequestPost.interviewsDocument = createEmptyAttachment()

  return newRequestPost
}

/**
 * 空の添付ファイル項目 生成
 * @returns 
 */
export function createEmptyAttachment() {
  const emptyAttachmen = utils.clone(AttachmentSchema) // { ...EstimateAttachmentSchema }
  return emptyAttachmen
}

// /**
//  * 更新
//  * @param {*} context 
//  * @param {*} orgRp 
//  * @param {*} rp 
//  * @returns 
//  */
// export async function updateDispatchDate(context, orgRp, rp) {

//   if (!orgRp._id) {
//     console.log('IDが指定されていません。')
//     return false
//   }

//   const updateData = {
//     type: REQUESTPOST_TASKS_TYPE,
//     rp
//   }

//   try {
//     await context.$pigeon.updateTask(orgRp._id, updateData, {})
//     return true
//   } catch (error) {
//     console.log('updateLeasePeriod error !!!')
//     console.log(error)
//     return false
//   }
// }

/**
 * No 生成
 */
async function createNo(context, rp) {
  const res = await context.$pigeon.getAutomaticNumber(
    REQUESTPOST_TASKS_TYPE,
    'requestNo',
    'apollo_request_no_setting',
    rp
  )
  return res
}


/**
 * 添付ファイルデータ 生成
 */
function createAttachmentRegistrationData(registrationData, targetAttachmentName) {
  if (registrationData[targetAttachmentName]) {
    registrationData[`${targetAttachmentName}_file_content`] = registrationData[targetAttachmentName].content || ''
    registrationData[`${targetAttachmentName}_file_name`] = registrationData[targetAttachmentName].originalName || ''
    registrationData[`${targetAttachmentName}_display_file_name`] = registrationData[targetAttachmentName].originalName || ''
  } else {
    registrationData[`${targetAttachmentName}_file_content`] = ''
    registrationData[`${targetAttachmentName}_file_name`] = ''
    registrationData[`${targetAttachmentName}_display_file_name`] = ''
  }
  delete registrationData[targetAttachmentName]
}

/**
 * 登録データ 生成
 */
function createRegistrationData(rp) {
  const registrationData = utils.clone(rp)

  // 添付ファイル項目設定
  createAttachmentRegistrationData(registrationData, 'commonFile')
  createAttachmentRegistrationData(registrationData, 'commonFile2')
  createAttachmentRegistrationData(registrationData, 'commonFile3')
  createAttachmentRegistrationData(registrationData, 'commonFile4')
  createAttachmentRegistrationData(registrationData, 'commonFile5')
  createAttachmentRegistrationData(registrationData, 'regularCleanImage')
  createAttachmentRegistrationData(registrationData, 'regularCleanImage2')
  createAttachmentRegistrationData(registrationData, 'regularCleanImage3')
  createAttachmentRegistrationData(registrationData, 'glassCleanImage')
  createAttachmentRegistrationData(registrationData, 'glassCleanImage2')
  createAttachmentRegistrationData(registrationData, 'glassCleanImage3')
  createAttachmentRegistrationData(registrationData, 'plantingPlaceFile')
  createAttachmentRegistrationData(registrationData, 'plantingPlaceFile2')
  createAttachmentRegistrationData(registrationData, 'plantingPlaceFile3')
  createAttachmentRegistrationData(registrationData, 'plantingReportFile')
  createAttachmentRegistrationData(registrationData, 'plantingReportFile2')
  createAttachmentRegistrationData(registrationData, 'plantingReportFile3')
  createAttachmentRegistrationData(registrationData, 'specialCleanImage')
  createAttachmentRegistrationData(registrationData, 'specialCleanImage2')
  createAttachmentRegistrationData(registrationData, 'specialCleanImage3')
  createAttachmentRegistrationData(registrationData, 'fireInspectReport')
  createAttachmentRegistrationData(registrationData, 'fireInspectReport2')
  createAttachmentRegistrationData(registrationData, 'fireInspectReport3')
  createAttachmentRegistrationData(registrationData, 'pressPumpInspectReport')
  createAttachmentRegistrationData(registrationData, 'pressPumpInspectReport2')
  createAttachmentRegistrationData(registrationData, 'pressPumpInspectReport3')
  createAttachmentRegistrationData(registrationData, 'constructionEquipmentInspectReport')
  createAttachmentRegistrationData(registrationData, 'constructionEquipmentInspectReport2')
  createAttachmentRegistrationData(registrationData, 'constructionEquipmentInspectReport3')
  createAttachmentRegistrationData(registrationData, 'specificInspectReport')
  createAttachmentRegistrationData(registrationData, 'specificInspectReport2')
  createAttachmentRegistrationData(registrationData, 'specificInspectReport3')
  createAttachmentRegistrationData(registrationData, 'waterTankReport')
  createAttachmentRegistrationData(registrationData, 'waterTankReport2')
  createAttachmentRegistrationData(registrationData, 'waterTankReport3')
  createAttachmentRegistrationData(registrationData, 'commonAreaReport')
  createAttachmentRegistrationData(registrationData, 'commonAreaReport2')
  createAttachmentRegistrationData(registrationData, 'commonAreaReport3')
  createAttachmentRegistrationData(registrationData, 'evInspectFile')
  createAttachmentRegistrationData(registrationData, 'evInspectFile2')
  createAttachmentRegistrationData(registrationData, 'evInspectFile3')
  createAttachmentRegistrationData(registrationData, 'interviewsCognitiveRes')
  createAttachmentRegistrationData(registrationData, 'interviewsDocument')
  if (!registrationData.employmentDocument_display_file_name) {
    createAttachmentRegistrationData(registrationData, 'employmentDocument')
  }

  return registrationData
}

/**
 * リクエストポスト検索（statusがremove以外）
 * @param {*} context 
 * @param {*} searchKeys 
 * @param {*} keyword 
 * @param {*} periodKey 期間絞込み対象プロパティ
 * @param {*} period 期間
 * @param {*} toDepartment  アポロ管財依頼先部署
 * @param {*} sortItem 
 * @param {*} sortBy 
 * @param {*} main メインタスク 要否
 * @param {*} withSub サブタスク 要否
 * @param {*} order 受注 要否
 * @param {*} employment 雇い入れ 要否
 * @param {*} toStaffUserKey
 * @param {*} byUserKey 
 * @param {*} limitDate 期限
 * @param {*} category カテゴリ
 * @param {*} clientName お客様会社
 * @param {*} clientPersonName お客様担当者
 * @param {*} staffName 担当者
 * @param {*} byUser 依頼主
 * @param {*} status ステータス
 * @param {*} opId オペレーターId（ユーザーID）
 * @returns 
 */
export async function searchRequestPosts(context, searchKeys, keyword, periodKey, period, toDepartment,
                                            sortItem, sortBy, main, withSub, order, employment, toStaffUserKey, byUserKey,
                                            limitDate, category, clientName, clientPersonName, staffName, byUser,
                                            status, opId) {
  // すべてOFF の場合は空返却
  if (!main && !order && !employment) return []

  // メインタスク 検索条件
  const searchCondition = {
    searchCondition: {
      type: REQUESTPOST_TASKS_TYPE,
      status: { $ne: "remove" }
    },
    sort: {}
  }

  // 受注 検索条件
  const orderSearchCondition = {
    searchCondition: {
      type: ORDER_TASKS_TYPE,
      status: { $ne: "remove" }
    },
    sort: {}
  }

  searchCondition.sort[sortItem] = sortBy

  // 期限 条件
  if (periodKey && period) {
    searchCondition.searchCondition[periodKey] = {}
    orderSearchCondition.searchCondition[periodKey] = {}
    if (period.start) {
      searchCondition.searchCondition[periodKey].$gte = period.start
      orderSearchCondition.searchCondition[periodKey].$gte = period.start
    }
    if (period.end) {
      searchCondition.searchCondition[periodKey].$lte = period.end
      orderSearchCondition.searchCondition[periodKey].$lte = period.end
    }
    if (Object.keys(searchCondition.searchCondition[periodKey]).length === 0) {
      delete searchCondition.searchCondition[periodKey]
    }
    if (Object.keys(orderSearchCondition.searchCondition[periodKey]).length === 0) {
      delete orderSearchCondition.searchCondition[periodKey]
    }
  }

  // アポロ管財依頼先部署
  if (toDepartment && toDepartment.length > 0) {
    searchCondition.searchCondition.toDepartment = { $regex: toDepartment, $options: 'i' }
    orderSearchCondition.searchCondition.toDepartment = { $regex: toDepartment, $options: 'i' }
  }

  // 期限
  if (limitDate && limitDate.length > 0) {
    searchCondition.searchCondition.limitDate = { $regex: limitDate, $options: 'i' }
    orderSearchCondition.searchCondition.limitDate = { $regex: limitDate, $options: 'i' }
  }

  // カテゴリ
  if (main) {
    // メイン あり
    if (category && category.length > 0) {
      // カテゴリの条件指定時は、指定に従う
      searchCondition.searchCondition.category = { $regex: category, $options: 'i' }
    } else {
      // カテゴリの指定なし、雇い入れの指定が無ければ、雇い入れを除外
      if (!employment) searchCondition.searchCondition.category = { $ne: '雇い入れ' }
    }
  } else {
    // メインなし
    // 雇い入れ指定時は、雇い入れのみ
    // 雇い入れも無しであれば、なにも取得しない
    if (!employment) searchCondition.searchCondition.category = { $eq: 'XXXXXXXXXXX' }
    else searchCondition.searchCondition.category = { $eq: '雇い入れ' }
  }
  if (category && category.length > 0) {
    orderSearchCondition.searchCondition.category = { $regex: category, $options: 'i' }
  }

  // お客様会社名
  if (clientName && clientName.length > 0) {
    searchCondition.searchCondition.clientName = { $regex: clientName, $options: 'i' }
    orderSearchCondition.searchCondition.clientName = { $regex: clientName, $options: 'i' }
  }

  // お客様担当者名
  if (clientPersonName && clientPersonName.length > 0) {
    const arrClientPersonName = clientPersonName.split(/\s+/g)

    let regexs = ''
    if (arrClientPersonName.length == 1) {
      regexs = arrClientPersonName[0]
    } else {
      // eslint-disable-next-line no-irregular-whitespace
      let re = clientPersonName.replace(/ |　/g, ')(?=.*')
      regexs = `^(?=.*${re}).*$`
    }
    searchCondition.searchCondition.clientPersonName = { $regex: regexs, $options: 'i' }
    orderSearchCondition.searchCondition.clientPersonName = { $regex: regexs, $options: 'i' }
  }

  let queryPrivate = null
  queryPrivate = { $or: [] }
  // 非公開依頼 以外はOK
  queryPrivate.$or.push( { category: { $nin: ["非公開依頼"] } })
  // 非公開依頼 の場合はログインユーザーが、担当者、依頼者、タスク依頼担当者のいずれかであること。
  let queryAuth = null
  queryAuth = { $or: [] }
  queryAuth.$or.push({ 'toStaffId': opId })
  queryAuth.$or.push({ 'byUserId': opId })
  queryAuth.$or.push({ 'taskRequest': { $elemMatch: { 'toStaffId': opId } } })
  queryAuth.$or.push({ 'taskRequestOrder': { $elemMatch: { 'toStaffId': opId } } })
  queryPrivate.$or.push(
    {
      $and: [
        { category: { $in: ["非公開依頼"] } },
        queryAuth
      ]
    }
  )

  // 担当者
  let queryStaff = null
  if (staffName && staffName.length > 0) {
    const arrStaffName = staffName.split(/\s+/g)

    let regexs = ''
    if (arrStaffName.length == 1) {
      regexs = arrStaffName[0]
    } else {
      // eslint-disable-next-line no-irregular-whitespace
      let re = staffName.replace(/ |　/g, ')(?=.*')
      regexs = `^(?=.*${re}).*$`
    }

    if (withSub) {
      queryStaff = { $or: [] }
      queryStaff.$or.push({ 'toStaffName': { $regex: regexs, $options: 'i' } })
      queryStaff.$or.push({ 'taskRequest': { $elemMatch: { 'toStaffName': { $regex: regexs, $options: 'i' } } } })
      queryStaff.$or.push({ 'taskRequestOrder': { $elemMatch: { 'toStaffName': { $regex: regexs, $options: 'i' } } } })
    } else {
      searchCondition.searchCondition.toStaffName = { $regex: regexs, $options: 'i' }
      orderSearchCondition.searchCondition.toStaffName = { $regex: regexs, $options: 'i' }
    }
  }

  // 依頼主
  if (byUser && byUser.length > 0) {
    const arrByUser = byUser.split(/\s+/g)

    let regexs = ''
    if (arrByUser.length == 1) {
      regexs = arrByUser[0]
    } else {
      // eslint-disable-next-line no-irregular-whitespace
      let re = byUser.replace(/ |　/g, ')(?=.*')
      regexs = `^(?=.*${re}).*$`
    }
    searchCondition.searchCondition.byUser = { $regex: regexs, $options: 'i' }
    orderSearchCondition.searchCondition.byUser = { $regex: regexs, $options: 'i' }
  }

  // ステータス
  if (status && status.length > 0) {
    searchCondition.searchCondition.status = status
    orderSearchCondition.searchCondition.status = status
  }

  const keywordCondition = buildMultipleKeywordSearchCondition(keyword || '', searchKeys || [], withSub, toStaffUserKey || '', byUserKey || '')

  try {
    var responseData = []
    let pipeline = []

    // 条件指定
    if ((main || employment) && order) {
      // メインと受注
      pipeline.push({ $match: { $or: [{ ...orderSearchCondition.searchCondition }, { ...searchCondition.searchCondition }] }})
    } else if (((main || employment) || employment)) {
      // メインのみ
      pipeline.push({ $match: { ...searchCondition.searchCondition }})
    } else if (order) {
      // 受注のみ
      pipeline.push({ $match: { ...orderSearchCondition.searchCondition }})
    }

    // サブタスク付与
    // メイン
    if (main) {
      pipeline.push({
        $lookup: {
          from: 'Tasks',
          let: {
            requestNo: '$requestNo',
            type: '$type'
          },
          pipeline: [{
            $match: {
              $expr: {
                $and: [
                  { $eq: ['$taskRequestRequestNo', '$$requestNo'] },
                  { $ne: ['$status', 'remove'] },
                  { $ne: ['$$type', 'order'] }
                ]
              }
            }
          }],
          as: 'taskRequest'
        }
      })
    }
    // 受注
    if (order) {
      pipeline.push({
        $lookup: {
          from: 'Tasks',
          let: {
            orderNo: '$orderNo',
          },
          pipeline: [{
            $match: {
              $expr: {
                $and: [
                  { $eq: ['$orderTaskRequestOrderNo', '$$orderNo'] },
                  { $ne: ['$status', 'remove'] }
                ]
              }
            }
          }],
          as: 'taskRequestOrder'
        }
      })
    }

    // キーワード 条件指定
    pipeline.push({ $match: { ...keywordCondition }})

    // 担当者 条件指定
    if (queryStaff) pipeline.push({ $match: { ...queryStaff }})

    // 非公開依頼 条件
    if (!PRIVATE_REQUEST_AUTH.includes(opId) && queryPrivate) pipeline.push({ $match: { ...queryPrivate }})

    // 個別 条件指定
    // if (condition.$and.length > 0)  pipeline.push({ $match: { ...condition }})

    // ソート指定
    pipeline.push({ $sort: { ...searchCondition.sort }})

    responseData = await context.$pigeon.aggregateTasks(pipeline)
    // responseData = removeStatusRemoveTask(responseData)
    const searchRequestPosts = responseData || []
    return searchRequestPosts
  } catch (error) {
    console.log('requestPostManager.searchData error !!!')
    console.log(error)
    return []
  }
}

/**
 * 進捗一覧のメイン取得
 * @param {*} context 
 * @param {*} sortItem ソート項目
 * @param {*} sortBy ソート順
 * @param {*} mainTask メイン表示
 * @param {*} orderTask 受注表示
 * @param {*} employmentTask 雇い入れ表示
 * @param {*} limit 期限
 * @param {*} filterMainTaskUser メインをログインユーザーで絞り込み人アイコン
 * @param {*} loginUserName ログインユーザー名
 * @param status ステータス
 * @param requestNo お問い合わせ番号（メイン）
 * @returns 
 */
export async function searchProgressMain(
  context,
  sortItem,
  sortBy,
  mainTask,
  orderTask,
  employmentTask,
  limit,
  filterMainTaskUser,
  loginUserName,
  status,
  requestNo
  ) {
    let type = {}
    type.$and = []
    type.$and.push({ $or: [] })
    if (mainTask || employmentTask) type.$and[0].$or.push({ type: 'apollo_request_post' })
    if (orderTask) type.$and[0].$or.push({ type: 'order' })
    if (!employmentTask) type.$and.push({category: { $ne: '雇い入れ' }})

    // 雇い入れのみ
    if (!mainTask && employmentTask && !orderTask) type.$and.push({category: { $eq: '雇い入れ' }})

    // すべてOFF
    if (!mainTask && !employmentTask && !orderTask) return []

    // ステータスが何も絞り込まれていない場合
    if (!status) {
      status = { $ne: 'remove' }
    }
    let searchCondition = {
      searchCondition: {
        $and: [type],
        status
      },
      sort: {}
    }
    // ソート
    if (sortItem) {
      searchCondition.sort[sortItem] = sortBy
    }
    // 個別検索 期限
    if (limit && (limit.start || limit.end)) {
      let limitDate = {}
      if (limit.start) {
        limitDate = { $gte: limit.start }
      }
      if (limit.end) {
        limitDate = { ...limitDate, $lte: limit.end }
      }
      searchCondition.searchCondition.$and.push({ limitDate })
    }
    // 人アイコン活性
    if (filterMainTaskUser) {
      searchCondition.searchCondition.$and.push({ toStaffName: { $regex: loginUserName } })
    }
    // お問い合わせ番号
    if (requestNo) {
      searchCondition.searchCondition.$and.push({ requestNo })
    }

    try {
      const responseData = await context.$pigeon.searchTasks(searchCondition)
      // console.log(searchCondition)
      // console.log(responseData)
    
      return responseData || []
    } catch (err) {
      console.log('searchProgressMain error !!!')
      console.log(err)
      return err
    }
  }

  /**
   * 進捗一覧のサブを取得（サブの検索は、フロントで行うこと！）
   * @param {*} context 
   * @param {*} mainIds メインタスクのID
   * @param {*} mainTask メイン表示
   * @param {*} orderTask 受注表示
   * @returns 
   */
  export async function searchProgressSub(
    context,
    mainIds,
    mainTask,
    orderTask
    ) {
    let type
    let ids = {}
    if (mainTask && orderTask) {
      type = { $or: [{ type: 'task_request' }, {type: 'task_request_order'}] }
      ids = { $or: [{ requestPostId: { $in: mainIds } }, { orderId: { $in: mainIds } }] }
    } else if (mainTask) {
      type = { type: 'task_request' }
      ids = { requestPostId: { $in: mainIds } }
    } else if (orderTask) {
      type = { type: 'task_request_order' }
      ids = { orderId: { $in: mainIds } }
    } else {
      return []
    }

    let searchCondition = {
      searchCondition: {
        $and: [type]
      },
      sort: { requestPostId: 1, orderId: 1, displayOrder: 1 }
    }
    searchCondition.searchCondition.$and.push(ids)
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    // console.log(searchCondition)
    // console.log('responseData')
    // console.log(responseData)
    
    return responseData || []
  }

// /**
//  * 進捗一覧検索（statusでの絞込み可能）
//  * @param {*} context 
//  * @param {*} searchKeys 検索対象プロパティ
//  * @param {*} keyword 検索ワード
//  * @param {*} status ステータス
//  * @param {*} period 期間
//  * @param {*} sortItem ソート対象プロパティー
//  * @param {*} sortBy 昇順降順
//  * @param {*} subStatus サブのステータス
//  * @param {*} opId オペレーターId（ユーザーID）
//  * @returns 
//  */
// export async function searchProgressData(context, searchKeys, keyword, status, period, sortItem, sortBy, subStatus
//         , andOrder = false, taskUserKey, isMainTaskUser, isSubTaskUser, isMainTask, isOrderTask, opId) {
//   console.log('requestPostManager.searchData')
//   console.log(keyword)
//   console.log(status)

//   let searchCondition = {
//     searchCondition: {
//       type: REQUESTPOST_TASKS_TYPE,
//       status
//     },
//     sort: {}
//   }

//   const orderSearchCondition = {
//     searchCondition: {
//       type: ORDER_TASKS_TYPE,
//       status
//     },
//     sort: {}
//   }

//   searchCondition.sort[sortItem] = sortBy

//   const keywordCondition = buildMultipleKeywordSearchConditionDate(keyword || '', searchKeys || [], period, subStatus, taskUserKey || '', isMainTaskUser, isSubTaskUser, isMainTask, isOrderTask)

//   try {
//     var responseData = []
//     let pipeline = []
//     if (andOrder) {
//       pipeline = [
//         {
//           $match: {
//             $or: [{ ...searchCondition.searchCondition }, { ...orderSearchCondition.searchCondition }]
//           }
//         },
//         {
//           $lookup: {
//             from: 'Tasks',
//             let: {
//               requestNo: '$requestNo',
//               type: '$type'
//             },
//             pipeline: [{
//               $match: {
//                 $expr: {
//                   $and: [
//                     { $eq: ['$taskRequestRequestNo', '$$requestNo'] },
//                     { $ne: ['$status', 'remove'] },
//                     { $ne: ['$$type', 'order'] }
//                   ]
//                 }
//               }
//             }],
//             as: 'taskRequest'
//           }
//         },
//         {
//           $lookup: {
//             from: 'Tasks',
//             let: {
//               orderNo: '$orderNo',
//             },
//             pipeline: [{
//               $match: {
//                 $expr: {
//                   $and: [
//                     { $eq: ['$orderTaskRequestOrderNo', '$$orderNo'] },
//                     { $ne: ['$status', 'remove'] }
//                   ]
//                 }
//               }
//             }],
//             as: 'taskRequestOrder'
//           }
//         },
//         {
//           $match: {
//             ...keywordCondition
//           }
//         },
//         {
//           $sort: { ...searchCondition.sort }
//         }
//       ]
//     } else {
//       pipeline = [
//         {
//           $match: {
//             ...searchCondition.searchCondition
//           }
//         },
//         {
//           $lookup: {
//             from: 'Tasks',
//             localField: 'requestNo',
//             foreignField: 'taskRequestRequestNo',
//             as: 'taskRequest'
//           }
//         },
//         {
//           $match: {
//             ...keywordCondition
//           }
//         },
//         {
//           $sort: { ...searchCondition.sort }
//         }
//       ]
//     }

//     // 非公開依頼 条件
//     let queryPrivate = null
//     queryPrivate = { $or: [] }
//     // 非公開依頼 以外はOK
//     queryPrivate.$or.push( { category: { $nin: ["非公開依頼"] } })
//     // 非公開依頼 の場合はログインユーザーが、担当者、依頼者、タスク依頼担当者のいずれかであること。
//     let queryAuth = null
//     queryAuth = { $or: [] }
//     queryAuth.$or.push({ 'toStaffId': opId })
//     queryAuth.$or.push({ 'byUserId': opId })
//     queryAuth.$or.push({ 'taskRequest': { $elemMatch: { 'toStaffId': opId } } })
//     queryAuth.$or.push({ 'taskRequestOrder': { $elemMatch: { 'toStaffId': opId } } })
//     queryPrivate.$or.push(
//       {
//         $and: [
//           { category: { $in: ["非公開依頼"] } },
//           queryAuth
//         ]
//       }
//     )
//     if (!PRIVATE_REQUEST_AUTH.includes(opId) && queryPrivate) pipeline.push({ $match: { ...queryPrivate }})

//     responseData = await context.$pigeon.aggregateTasks(pipeline)

//     const searchRequestPosts = responseData || []
//     console.log(searchRequestPosts)
//     return searchRequestPosts
//   } catch (error) {
//     console.log('requestPostManager.searchData error !!!')
//     console.log(error)
//     return []
//   }
// }

/**
 * 検索クエリ生成
 * @param {*} keywordText 検索ワード
 * @param {*} searchKeys 検索対象プロパティー
 * @param withSub サブタスク表示
 * @returns 
 */
function buildMultipleKeywordSearchCondition(keywordText, searchKeys, withSub, toStaffUserKeywordText, byUserKeywordText) {
  keywordText = keywordText.trim()
  const condition = { $and: [] }
  if (keywordText === '' && toStaffUserKeywordText === '' && byUserKeywordText === '') {
    return null
  } else {
    if (keywordText) {
      const keywords = keywordText.split(/\s+/g)

      keywords.forEach(function (keyword) {
        // カッコは無視して検索
        keyword = removeMark(keyword)
        console.log(keyword)

        const query = { $or: [] }
        searchKeys.forEach(function (searchKey) {
          const expression = {}
          expression[searchKey] = { $regex: keyword, $options: 'i' }
          query.$or.push(expression)
        });

        if (withSub) {
          query.$or.push({ 'taskRequest': { $elemMatch: { 'toStaffName': { $regex: keyword, $options: 'i' } } } })
          query.$or.push({ 'taskRequestOrder': { $elemMatch: { 'toStaffName': { $regex: keyword, $options: 'i' } } } })
        }

        condition.$and.push(query);
      });
    }

    if (toStaffUserKeywordText) {
      const toStaffUserKeywords = toStaffUserKeywordText.split(/\s+/g)

      toStaffUserKeywords.forEach(function (toStaffUserKeyword) {
        const query = { $or: [] }
        searchKeys.forEach(function (searchKey) {
          const expression = {}
          if (searchKey == 'toStaffName') {
            expression['toStaffName'] = { $regex: toStaffUserKeyword, $options: 'i' }
            query.$or.push(expression)
          }
        });

        if (withSub) {
          query.$or.push({ 'taskRequest': { $elemMatch: { 'toStaffName': { $regex: toStaffUserKeyword, $options: 'i' } } } })
          // サブ担当者の未定もヒット
          query.$or.push({ 'taskRequest': { $elemMatch: { 'toStaffName': { $regex: '未定', $options: 'i' } } } })
          query.$or.push({ 'taskRequestOrder': { $elemMatch: { 'toStaffName': { $regex: toStaffUserKeyword, $options: 'i' } } } })
          // 受注サブ担当者の未定もヒット
          query.$or.push({ 'taskRequestOrder': { $elemMatch: { 'toStaffName': { $regex: '未定', $options: 'i' } } } })
        }

        // メインタスク担当者の未定もヒット
        query.$or.push({ 'toStaffName': { $regex: '未定', $options: 'i' } })
        condition.$and.push(query);
      });
    }

    if (byUserKeywordText) {
      const byUserKeywords = byUserKeywordText.split(/\s+/g)

      byUserKeywords.forEach(function (byUserKeyword) {
        const query = { $or: [] }
        searchKeys.forEach(function (searchKey) {
          const expression = {}
          if (searchKey == 'byUser') {
            expression['byUser'] = { $regex: byUserKeyword, $options: 'i' }
            query.$or.push(expression)
          }
        });

        condition.$and.push(query);
      });
    }

    // if (filterUser) {
    //   let item = condition.$and.splice(-2)
    //   for (let index = 0; index < item.length; index++) {
    //     item[index].$or.push({ type: 'order'})
    //     condition.$and.push(item[index])
    //   }
    // }
    return condition;
  }
}

// /**
//  * 検索クエリ生成 サブタスク一覧
//  * @param {*} keywordText 検索ワード
//  * @param {*} searchKeys 検索対象プロパティー
//  * @param period 期間
//  * @param subStatus ステータス
//  * @returns 
//  */
// function buildMultipleKeywordSearchConditionDate(keywordText, searchKeys, period, subStatus, taskUserKeywordText, isMainTaskUser, isSubTaskUser, isMainTask, isOrderTask) {

//   keywordText = keywordText.trim()
//   let condition = { $and: [] }
//   if (keywordText != '') {
//     const keywords = keywordText.split(/\s+/g)

//     keywords.forEach(function (keyword) {
//       // カッコは無視して検索
//       keyword = removeMark(keyword)
//       // console.log(keyword)
      
//       const query = { $or: [] }
//       searchKeys.forEach(function (searchKey) {
//         const expression = {}
//         expression[searchKey] = { $regex: keyword, $options: 'i' }
//         query.$or.push(expression)
//       });

//       // メインタスクのサブ表示時
//       if (isMainTask) {
//         query.$or.push({ 'taskRequest': { $elemMatch: { $or: [{ 'toStaffName': { $regex: keyword, $options: 'i' } }, { 'departmentName': { $regex: keyword, $options: 'i' } }, { 'requestTask': { $regex: keyword, $options: 'i' } }] } } })
//       }
//       // 受注タスクのサブ表示時
//       if (isOrderTask) {
//         query.$or.push({ 'taskRequestOrder': { $elemMatch: { $or: [{ 'toStaffName': { $regex: keyword, $options: 'i' } }, { 'departmentName': { $regex: keyword, $options: 'i' } }, { 'requestTask': { $regex: keyword, $options: 'i' } }] } } })
//       }
//       condition.$and.push(query)
//     })
//   }

//   // アイコンの検索があれば、and条件にするため、配列追加
//   if (isSubTaskUser || period.start || period.end || subStatus != '') {
//     let query = { $or: [] }
//     if (isMainTask) {
//       let q1 = { 'taskRequest': { $elemMatch: {} } }
//       query.$or.push(q1)
//     }
//     if (isOrderTask) {
//       let q2 = { 'taskRequestOrder': { $elemMatch: {} } }
//       query.$or.push(q2)
//     }
//     if (query.$or.length) {
//       condition.$and.push(query)
//     }
//   }

//   // 人アイコン活性時
//   if (taskUserKeywordText != '') {
//     const taskUserKeywordTexts = taskUserKeywordText.split(/\s+/g)
//     let regexs = ''
//     if (taskUserKeywordTexts.length == 1) {
//       regexs = taskUserKeywordTexts[0]
//     } else {
//       // eslint-disable-next-line no-irregular-whitespace
//       let re = taskUserKeywordText.replace(/ |/g, ')(?=.*')
//       regexs = `^(?=.*${re}).*$`
//     }
//     // メイン担当者をログインユーザーで絞り込み時
//     if (isMainTaskUser) {
//       const query = { $and: [] }
//       searchKeys.forEach(function (searchKey) {
//         const expression = {}
//         if (searchKey == 'toStaffName') {
//           expression['toStaffName'] = { $regex: regexs, $options: 'i' }
//           query.$and.push(expression)
//         }
//       })
//       condition.$and.push(query)
//     }

//     // メイン担当者をログインユーザーで絞り込み時
//     if (isSubTaskUser) {
//       condition = addConditionsInSub('toStaffName', { $regex: regexs, $options: 'i' }, condition, isMainTask, isOrderTask)
//     }
//     // console.log(condition)
//   }

//   // 期間（サブ）を絞り込み時
//   if (period.start || period.end) {
//     // 終了のみ
//     if (period.start && !period.end) {
//       condition = addConditionsInSub('limitDate', { $gte: period.start }, condition, isMainTask, isOrderTask)
    
//     // 開始のみ
//     } else if (!period.start && period.end) {
//       condition = addConditionsInSub('limitDate', { $lte: period.end }, condition, isMainTask, isOrderTask)

//     // 開始・終了 両方
//     } else if (period.start && period.end) {
//       condition = addConditionsInSub('limitDate', { $gte: period.start, $lte: period.end }, condition, isMainTask, isOrderTask)
//     }
//   }
//   condition = addConditionsInSub('status', subStatus, condition, isMainTask, isOrderTask)
//   // console.log(condition)
//   return condition
// }

// /**
//  * サブタスク、受注サブタスク対象の検索
//  * @param target 対象プロパティ
//  * @param value 値
//  * @param condition すでにある条件
//  * @param isMainTask メイン表示するか
//  * @param isOrderTask 受注表示するか
//  */
// function addConditionsInSub(target, value, condition, isMainTask, isOrderTask) {
//   if (!value || value == '') {
//     return condition
//   }

//   // 検索条件が他にある場合
//   if (condition && condition.$and.length) {

//     for (let andIdx = 0; andIdx < condition.$and.length; andIdx++) {
//       // and内にorがある場合
//       if (condition.$and[andIdx].$or) {
//         for (let orIdx = 0; orIdx < condition.$and[andIdx].$or.length; orIdx++) {
//           const c = condition.$and[andIdx].$or[orIdx]
//           if (c.taskRequest) {
//             c.taskRequest.$elemMatch[target] = value
//           }
//           if (c.taskRequestOrder) {
//             c.taskRequestOrder.$elemMatch[target] = value
//           }
//         }
//       }
//       // and内にandがある場合
//       if (condition.$and[andIdx].$and) {
//         for (let andIdx2 = 0; andIdx2 < condition.$and[andIdx].$and.length; andIdx2++) {
//           const cA = condition.$and[andIdx].$and[andIdx2]
//           if (cA.taskRequest) {
//             cA.taskRequest.$elemMatch[target] = value
//           }
//           if (cA.taskRequestOrder) {
//             cA.taskRequestOrder.$elemMatch[target] = value
//           }
//         }
//       }
//     }

//   // 他に条件が何もない場合
//   } else {
//     let query = { $or: [] }
//     if (isMainTask) {
//       let q1 = { 'taskRequest': { $elemMatch: { [target]: value } } }
//       query.$or.push(q1)
//     }
//     if (isOrderTask) {
//       let q2 = { 'taskRequestOrder': { $elemMatch: { [target]: value } } }
//       query.$or.push(q2)
//     }
//     if (query.$or.length) {
//       condition.$and.push(query)
//     }
//   }
//   // console.log(condition)
//   return condition
// }

/**
 * カッコは無視して検索
 * @param {*} keyword 検索キーワード入力値
 * @returns 
 */
function removeMark(keyword){
  // 前後にあるカッコは削除
  if (['（', '(', '）', ')'].includes(keyword.substring(0, 1))) {
    keyword = keyword.substring(1)
  }
  if (['（', '(', '）', ')'].includes(keyword.substring(keyword.length-1, keyword.length))) {
    keyword = keyword.substring(0, keyword.length-1)
  }

  // 文字列内にカッコがあれば、正規表現でAND条件にする
  let target = keyword
  // (?=.*)は問題ないので、対象の記号を全置換
  target = target.replace(/（|\(|）|\)/g, ')(?=.*')
  if (target != keyword) {
    target = `^(?=.*${target}).*$`
    keyword = target
  }
  return keyword
}

// リクエストポストのステータス変更
export async function markAsDone(context, allocationId) {
  try {
    await context.$pigeon.completeTask(allocationId)
    return true
  } catch (error) {
    console.log('markAsDone error !!!')
    console.log(error)
    return false
  }
}

// リクエストポスト 削除のみステータス検索
export async function searchRequestPostsRemoveOnly(context) {

  const searchCondition = {
    searchCondition: {
      type: REQUESTPOST_TASKS_TYPE,
      status: "remove"
    }
  }

  const responseData = await context.$pigeon.searchTasks(searchCondition)
  const searchRequestPosts = responseData || []
  return searchRequestPosts
}

/**
 * リクエストポストデータをステータスによって絞込み
 * @param {*} context 
 * @param {*} status ステータス
 * @returns 
 */
export async function searchByStatus(context, status) {

  const searchCondition = {
    searchCondition: {
      type: REQUESTPOST_TASKS_TYPE,
      status
    }
  }

  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const result = responseData || []
    return result
  } catch (error) {
    console.log('requestPostManager.searchByStatus error !!!')
    console.log(error)
    return []
  }
}

/**
 * 論理削除
 * @param {*} context 
 * @param {*} id requestPostId
 * @returns 
 */
export async function deleteMain(context, id) {
  try {
    await context.$pigeon.deleteTask(id)
    return true
  } catch (error) {
    console.log('deleteMain error !!!')
    console.log(error)
    return false
  }
}

export async function upDateInterviews(context, _id) {
  const data = await getRequestPost(context, _id)
  if (data) {
    // 雇い入れフラグをtrueにする（同じ面接から複数の雇い入れを作成するのを防ぐため）
    data.isEmployment = true
    update(context, data, false)
  }

}