<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative bg-white">
    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <div>
        <header>
          <h1 class="launcher-header"
            :class="colorCode == 'bg-light-blue-700' ? 'purple-header' : colorCode == 'bg-pink-400' ? 'orange-header' : 'green-header'">
            <img src="images/logo.png" class="launcher-logo">
            <UserMenu class="launcher-user-menu" />
          </h1>
        </header>
      </div>
      <div class="mt-12 mb-5">
        <img src="images/requestpostlogo.png" width="350px" class="launcher-image" />
        <div class="version">{{ version }}</div>
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <div v-for="feature in features" :key="feature.name" class="pt-6" v-show="feature.auth">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8 h-full" v-if="feature.auth">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-400 rounded-md shadow-lg">
                    <Icon :iconName="feature.iconName" :iconType="feature.iconType || 'outline'" :strokeWidth="2"
                      class="h-7 w-7 text-white" />
                  </span>
                </div>
                <h3 class="mt-6 text-lg font-semibold text-gray-900 tracking-tight">{{ feature.name }}</h3>
                <p class="mt-5 text-sm text-gray-500 px-3 text-left whitespace-pre-wrap">
                  {{ feature.description }}
                </p>

                <div class="flex-col mt-4 items-center grid grid-cols-2 px-3">
                  <div v-for="(item, index) in feature.items" :key="item.name" class="flex justify-start"
                    :class="index % 2 != 0 ? 'ml-2' : ''">
                    
                    <template v-if="item.name !== '' && item.auth && !item.etc">
                      <a v-if="item.modal" @click="modalOpen(item.modalName)"
                        class="flex items-center my-1 text-sm font-medium  transition ease-in-out duration-150 cursor-pointer"
                        :class="item.class">
                        <Icon :iconName="item.iconName" class="w-4 h-4 mr-1" /> {{ item.name }}
                      </a>
                      <a v-else-if="item.external" :href="item.url" :target="item.target"
                        class="flex items-center my-1 text-sm font-medium  transition ease-in-out duration-150"
                        :class="item.class">
                        <Icon :iconName="item.iconName" class="w-4 h-4 mr-1" /> {{ item.name }}
                      </a>
                      <router-link v-else :to="item.url"
                        class="flex items-center my-1 text-sm font-medium transition ease-in-out duration-150"
                        :class="item.class">
                        <Icon :iconName="item.iconName" class="w-4 h-4 mr-1" /> {{ item.name }}
                      </router-link>
                    </template>

                    <template v-else-if="item.name !== '' && item.auth && item.initEtc">
                      <div class="px-3" style="text-align: left; margin-top: 0 !important;">
                        <button class="my-1 text-sm font-medium  transition ease-in-out duration-150" @click="showOption(item.initEtc)">
                          {{ optionSw[item.initEtc] ?  '▼' :  '▶' }} その他
                        </button>
                      </div>
                    </template>
                  </div>
                </div>

                <div
                  :class="['flex-col', 'mt-4', 'items-center', 'grid', 'grid-cols-2' , 'px-3', { 'hidden': !optionSw[feature.name] }]"
                  style="margin-top: 0px !important;"
                >
                  <div v-for="(item,index) in feature.items.filter(item => item.etc === true)" :key="item.name"
                    class="flex justify-start"  :class="index % 2 != 0 ? 'ml-2' : ''">
                    <template v-if="item.name !== '' && item.auth">
                      <a v-if="item.modal" @click="modalOpen(item.modalName)"
                        class="flex items-center my-1 text-sm font-medium  transition ease-in-out duration-150 cursor-pointer"
                        :class="item.class">
                        <Icon :iconName="item.iconName" class="w-4 h-4 mr-1" /> {{ item.name }}
                      </a>
                      <a v-else-if="item.external" :href="item.url" :target="item.target"
                        class="flex  my-1 text-sm font-medium  transition ease-in-out duration-150 " :class="item.class">
                        <Icon :iconName="item.iconName" class="w-4 h-4 mr-1" /> {{ item.name }}
                      </a>
                      <router-link v-else :to="item.url"
                        class="flex  my-1 text-sm font-medium transition ease-in-out duration-150" :class="item.class">
                        <Icon :iconName="item.iconName" class="w-4 h-4 mr-1" /> {{ item.name }}
                      </router-link>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 発注マスタ編集 -->
    <EstimatePurchaseModal 
      v-model="purchaseModalShow"
      :purchaseModalShow="purchaseModalShow"
      @closePurchaseModal="closePurchaseModal"
    />
    <!-- 写真報告テンプレート -->
    <TemplateModal 
      v-model="photoReportModalShow"
      :photoReportModalShow="photoReportModalShow"
      @close="closePhotoReportModal"
    />
    <!-- 写真報告書カラー編集 -->
    <photoReportColorModal 
      v-model="photoReportColorModalShow"
      :photoReportColorModalShow="photoReportColorModalShow"
      @closePhotoReportColorModal="closePhotoReportColorModal"
    />
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import { PIGEON_CONFIG } from '@/config'
import UserMenu from '@components/UserMenu.vue'
import EstimatePurchaseModal from '../../Estimation/components/EstimatePurchaseModal.vue'
import TemplateModal from '../../PhotoReport/components/TemplateModal.vue'
import PhotoReportColorModal from '../../PhotoReport/components/PhotoReportColorModal.vue'
import * as backend from '@libs/backend'

export default {
  components: {
    Icon,
    UserMenu,
    EstimatePurchaseModal,
    TemplateModal,
    PhotoReportColorModal
  },

  computed: {
    appVersion() {
      return this.$store.getters.appVersion
    },
  },

  methods: {
    showOption(i) {
      this.optionSw[i] = !this.optionSw[i]
    },

    /**
     * モーダルopen
     */
    modalOpen(target) {
      this[target] = true
    },

    /**
     * モーダルclose
     */
    closePurchaseModal() {
      this.purchaseModalShow = false
    },

    /**
     * 写真報告テンプレートモーダルclose
     */
    closePhotoReportModal() {
      this.photoReportModalShow = false
    },

    /**
     * 写真報告書カラー モーダルclose
     */
     closePhotoReportColorModal() {
      this.photoReportColorModalShow = false
    }
  },

  async created() {

    // バージョン取得
    let retVar = await backend.searchData('param', { key: 'version' })
    if (retVar.data.data && retVar.data.data.length > 0) {
      this.version = 'ver: ' + retVar.data.data[0].parameterValue
    }

    let auth = this.$store.getters.auth
    if (auth && auth.adminAuth) {
      this.adminAuth = true
    }
    if (auth && auth.userAuth) {
      this.userAuth = true
    }
    if (auth && auth.mainAuth) {
      this.mainAuth = true
    }
    if (auth && auth.subAuth) {
      this.subAuth = true
    }
    if (auth && auth.estimateAuth) {
      this.estimateAuth = true
    }
    if (auth && auth.patrolAuth) {
      this.patrolAuth = true
    }
    if (auth && auth.roundCleanAuth) {
      this.roundCleanAuth = true
    }
    if (auth && auth.fixedCleanAuth) {
      this.fixedCleanAuth = true
    }
    if (auth && auth.facilityPatrolAuth) {
      this.facilityPatrolAuth = true
    }
    if (auth && auth.photoReportAuth) {
      this.photoReportAuth = true
    }
    if (auth && auth.partTimer) {
      this.partTimer = true
    }

    this.features = [
      {
        name: 'メインタスク',
        description: 'メインタスクを登録・管理します。',
        iconName: 'UserGroup',
        auth: this.mainAuth,
        items: [
          {
            name: '新規登録',
            url: `./RequestPost`,
            iconName: 'PencilAlt',
            external: true,
            auth: this.mainAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '一覧',
            url: `./RequestPostList`,
            iconName: 'ClipboardList',
            external: true,
            auth: this.mainAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          }
        ]
      },
      {
        name: 'サブタスク',
        description: 'メインで発生するサブタスクを登録・管理します。',
        iconName: 'Share',
        auth: this.subAuth,
        items: [
          {
            name: '進捗一覧',
            url: `./RequestPostProgressList`,
            iconName: 'ClipboardList',
            external: true,
            auth: this.subAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          }
        ]
      },
      {
        name: '見積書',
        description: '見積書の作成・管理します。',
        iconName: 'CurrencyYen',
        auth: this.estimateAuth,
        items: [
          // {
          //   name: '新規登録',
          //   url: `./Estimate?estimateType=normal`,
          //   iconName: 'PencilAlt',
          //   external: true
          // },
          {
            name: '承認待ち一覧',
            url: `./EstimateList?tabId=awaitingApproval`,
            iconName: 'ClipboardList',
            external: true,
            auth: this.estimateAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '一覧',
            url: `./EstimateList?tabId=all`,
            iconName: 'ClipboardList',
            external: true,
            auth: this.estimateAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: 'メインタスク未完了一覧',
            url: `./EstimateList?tabId=incomplete`,
            iconName: 'ClipboardList',
            external: true,
            etc: true,
            initEtc: '見積書',
            auth: this.estimateAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '受注一覧',
            url: `./EstimateList?tabId=ordered`,
            iconName: 'ClipboardList',
            external: true,
            etc: true,
            auth: this.estimateAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '見積集計',
            url: `./EstimateChart`,
            iconName: 'ChartSquareBar',
            external: true,
            etc: true,
            auth: this.estimateAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '見積り単価',
            url: `./EstimatePriceMaster`,
            iconName: 'CurrencyYen',
            external: true,
            etc: true,
            auth: this.estimateAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
        ]
      },
      {
        name: '品質巡回',
        description: '品質巡回の設定・状況を表示します。',
        iconName: 'ClipboardCheck',
        auth: this.patrolAuth,
        items: [
          {
            name: '未点検一覧',
            url: `./UnPatrolList`,
            iconName: 'ClipboardList',
            external: true,
            auth: this.patrolAuth,
            class: 'pink text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '予定一覧',
            url: `./ScheduleListByStaff`,
            iconName: 'Calendar',
            external: true,
            auth: this.patrolAuth,
            class: 'light-green text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '点検項目設定',
            url: `./PatrolInspection`,
            iconName: 'PencilAlt',
            external: true,
            auth: this.patrolAuth,
            etc: true,
            initEtc: '品質巡回',
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '点検サイクル設定',
            url: `./PatrolTerm`,
            iconName: 'PencilAlt',
            external: true,
            auth: this.patrolAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '点検一覧',
            url: `./CheckList`,
            iconName: 'ClipboardCheck',
            external: true,
            auth: this.patrolAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: 'ダウンロード',
            url: `./Download`,
            iconName: 'Download',
            external: true,
            auth: this.patrolAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '得意先毎の未点検',
            url: `./CountUnPatrolByClient`,
            iconName: 'DocumentRemove',
            external: true,
            auth: this.patrolAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: 'クレーム未点検',
            url: `./ClaimUnPatrolList`,
            iconName: 'ClipboardList',
            external: true,
            auth: this.patrolAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: 'クレーム点検一覧',
            url: `./ClaimCheckList`,
            iconName: 'ClipboardCheck',
            external: true,
            auth: this.patrolAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
        ]
      },
      {
        name: '巡回清掃',
        description: '巡回清掃の設定・状況を表示します。',
        iconName: 'ClipboardCheck',
        auth: this.roundCleanAuth,
        items: [
          {
            name: '未点検一覧',
            url: `./RoundUnPatrolList`,
            iconName: 'ClipboardList',
            external: true,
            auth: this.roundCleanAuth,
            class: 'pink text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '予定一覧',
            url: `./RoundScheduleListByStaff`,
            iconName: 'Calendar',
            external: true,
            auth: this.roundCleanAuth,
            class: 'light-green text-indigo-600 hover:text-indigo-500'
          },
          // {
          //   name: '点検項目設定',
          //   url: `./PatrolInspection`,
          //   iconName: 'PencilAlt',
          //   external: true,
          //   auth: this.roundCleanAuth,
          //   etc: true,
          //   initEtc: true,
          //   class: 'text-indigo-600 hover:text-indigo-500'
          // },
          // ↓この条件は使用しなくなったが、他で使う可能性があるため、残す
          // {
          //   name: '対象物件マスタ',
          //   url: `./RoundSiteListMaster`,
          //   iconName: 'PencilAlt',
          //   external: true,
          //   auth: this.roundCleanAuth,
          //   initEtc: '巡回清掃',
          //   etc: true,
          //   class: 'text-indigo-600 hover:text-indigo-500'
          // },
          {
            name: '報告書項目設定',
            url: `./RoundPatrolInspection`,
            iconName: 'PencilAlt',
            external: true,
            auth: this.roundCleanAuth,
            etc: true,
            initEtc: '巡回清掃',
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '施工月設定',
            url: `./RoundTerm`,
            iconName: 'PencilAlt',
            external: true,
            auth: this.roundCleanAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '作業完了一覧',
            url: `./RoundCheckList`,
            iconName: 'ClipboardCheck',
            external: true,
            auth: this.roundCleanAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
        ]
      },
      {
        name: '定期清掃',
        description: '定期清掃の設定・状況を表示します。',
        iconName: 'ClipboardCheck',
        auth: this.fixedCleanAuth,
        items: [
          {
            name: '未完了一覧',
            url: `./FixedUnPatrolList`,
            iconName: 'ClipboardList',
            external: true,
            auth: this.fixedCleanAuth,
            class: 'pink text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '予定一覧',
            url: `./FixedScheduleListByStaff`,
            iconName: 'Calendar',
            external: true,
            auth: this.fixedCleanAuth,
            class: 'light-green text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '報告書項目設定',
            url: `./FixedPatrolInspection`,
            iconName: 'PencilAlt',
            external: true,
            auth: this.fixedCleanAuth,
            initEtc: '定期清掃',
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '施工月・項目設定',
            url: `./FixedPatrolTerm`,
            iconName: 'PencilAlt',
            external: true,
            auth: this.fixedCleanAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '棟設定',
            url: `./FixedRidge`,
            iconName: 'PencilAlt',
            external: true,
            auth: this.fixedCleanAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '張り紙',
            url: `./FixedPoster`,
            iconName: 'Archive',
            external: true,
            auth: this.fixedCleanAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '作業完了一覧',
            url: `./FixedCheckList`,
            iconName: 'ClipboardCheck',
            external: true,
            auth: this.fixedCleanAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
        ]
      },
      {
        name: '設備点検',
        description: '設備点検の設定・状況を表示します。',
        iconName: 'ClipboardCheck',
        auth: this.facilityPatrolAuth,
        items: [
          {
            name: '未点検一覧',
            url: `./FacilityUnPatrolList`,
            iconName: 'ClipboardList',
            external: true,
            auth: this.facilityPatrolAuth,
            class: 'pink text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '予定一覧',
            url: `./FacilityScheduleListByStaff`,
            iconName: 'Calendar',
            external: true,
            auth: this.facilityPatrolAuth,
            class: 'light-green text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '項目・ｻｲｸﾙ設定',
            url: `./FacilityTerm`,
            iconName: 'PencilAlt',
            external: true,
            auth: this.facilityPatrolAuth,
            initEtc: '設備点検',
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: 'チーム名設定',
            url: `./FacilityTeamNameMaster`,
            iconName: 'PencilAlt',
            external: true,
            auth: this.facilityPatrolAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '点検一覧',
            url: `./FacilityCheckList`,
            iconName: 'ClipboardCheck',
            external: true,
            auth: this.facilityPatrolAuth,
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
        ]
      },
      {
        name: '写真報告書',
        description: '提案書・現地調査・環境整備等が作成できます。',
        iconName: 'ClipboardCheck',
        auth: this.photoReportAuth,
        items: [
          {
            name: '新規作成',
            url: `./`,
            iconName: 'ClipboardList',
            external: true,
            auth: this.photoReportAuth,
            modal: true,
            modalName: 'photoReportModalShow',
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '一覧',
            url: `./PhotoReportList`,
            iconName: 'Calendar',
            external: true,
            auth: this.photoReportAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '項目設定',
            url: `./PhotoReportItemSetting`,
            iconName: 'PencilAlt',
            external: true,
            auth: this.photoReportAuth,
            initEtc: '写真報告書',
            etc: true,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '配色設定',
            url: `./`,
            iconName: 'ColorSwatch',
            external: true,
            etc: true,
            auth: this.photoReportAuth,
            modal: true,
            modalName: 'photoReportColorModalShow',
            class: 'text-indigo-600 hover:text-indigo-500'
          }
        ]
      },
      {
        name: 'マスタメンテナンス',
        description: 'マスタデータのメンテナンスを行います。',
        iconName: 'Database',
        auth: (this.adminAuth || this.userAuth),
        items: [
          {
            name: 'サブタスク',
            url: `./SubTaskMaster`,
            iconName: 'PencilAlt',
            external: true,
            target: '_blank',
            auth: this.adminAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: 'タスク依頼セット',
            url: `./SubTaskSetMaster`,
            iconName: 'PencilAlt',
            external: true,
            target: '_blank',
            auth: this.adminAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '日常・管理エリア',
            url: `${PIGEON_CONFIG.baseUrl}form?formId=eria_normal_management_master`,
            iconName: 'PencilAlt',
            external: true,
            target: '_blank',
            etc: true,
            initEtc: 'マスタメンテナンス',
            auth: this.adminAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '定期清掃エリア',
            url: `${PIGEON_CONFIG.baseUrl}form?formId=eria_fixed_master`,
            iconName: 'PencilAlt',
            external: true,
            target: '_blank',
            etc: true,
            auth: this.adminAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '承認ルート',
            url: `./EstimateApprovalMaster`,
            iconName: 'PencilAlt',
            external: true,
            etc: true,
            auth: this.adminAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '承認チャットワーク',
            url: `${PIGEON_CONFIG.baseUrl}form?formId=estimate_approver_chatwork_master`,
            iconName: 'PencilAlt',
            external: true,
            etc: true,
            target: '_blank',
            auth: this.adminAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: 'ユーザー設定',
            url: `./UserMaster`,
            iconName: 'PencilAlt',
            external: true,
            etc: true,
            auth: (this.adminAuth || this.userAuth),
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '部署設定',
            url: `./DepartmentMaster`,
            iconName: 'PencilAlt',
            external: true,
            etc: true,
            auth: this.adminAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '巡回関連スタッフ',
            url: `./PatrolStaffMaster`,
            iconName: 'PencilAlt',
            external: true,
            etc: true,
            auth: this.adminAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '発注先マスタ',
            url: `./`,
            iconName: 'PencilAlt',
            external: true,
            etc: true,
            auth: this.adminAuth,
            modal: true,
            modalName: 'purchaseModalShow',
            class: 'text-indigo-600 hover:text-indigo-500'
          },
          {
            name: '作業内容マスタ',
            url: `./estimateWorkCodeMaster`,
            iconName: 'PencilAlt',
            external: true,
            etc: true,
            auth: this.adminAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },
        ]
      },
      {
        name: 'ログ',
        description: '操作ログを確認いただけます。',
        iconName: 'ClipboardList',
        auth: this.adminAuth,
        items: [
          {
            name: 'ログ一覧',
            url: `./LogList`,
            iconName: 'ClipboardList',
            external: true,
            auth: this.adminAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          }
        ]
      },
    ]

    // 管理者権限がなく、ユーザー設定権限がある場合
    if (!this.adminAuth && this.userAuth) {
      for (let i = 0; i < this.features.length; i++) {
        const f = this.features[i]
        
        if (f.name == 'マスタメンテナンス') {
          // ユーザー設定を削除
          f.items.splice(6, 1)
          // ユーザー設定を先頭にする
          f.items.unshift({
            name: 'ユーザー設定',
            url: `./UserMaster`,
            iconName: 'PencilAlt',
            external: true,
            auth: this.userAuth,
            class: 'text-indigo-600 hover:text-indigo-500'
          },)
          break
        }
      }
    }

    // パートさんの場合
    if (this.partTimer) {
      let name = ['品質巡回', '巡回清掃', '定期清掃', '設備点検', '写真報告書']
      for (let i = 0; i < this.features.length; i++) {
        const f = this.features[i]
        
        if (name.includes(f.name)) {
          f.items.splice(0, 1)
          f.items.splice(1, f.items.length)
          break
        }
      }
    }

    // キャッシュチェック
    if (this.version.replace('ver: ', '') != this.$store.getters.appVersion.trim()) alert('古いページを開いています。ブラウザを閉じて開き直してください。')

    // セッションストレージを空にする（メイン一覧）
    sessionStorage.removeItem('requestPostListConditionReset')
    // セッションストレージを空にする（進捗一覧）
    sessionStorage.removeItem('requestPostProgressListConditionReset')
    // セッションストレージを空にする（見積一覧）
    sessionStorage.removeItem('estimateListConditionReset')
  },

  data() {
    return {
      features: [],
      // 管理者権限
      adminAuth: false,
      // ユーザー設定
      userAuth: false,
      // メインタスク権限
      mainAuth: false,
      // サブタスク権限
      subAuth: false,
      // 見積もり権限
      estimateAuth: false,
      // 品質巡回権限
      patrolAuth: false,
      // 巡回清掃権限
      roundCleanAuth: false,
      // 定期清掃権限
      fixedCleanAuth: false,
      // 設備点検権限
      facilityPatrolAuth: false,
      // 写真報告権限
      photoReportAuth: false,
      // パート
      partTimer: false,
      // ヘッダカラー
      colorCode: PIGEON_CONFIG.colorCode,
      // バージョン
      version: '',
      // その他スイッチ
      optionSw: { '見積書': false, '品質巡回': false, '巡回清掃': false, '定期清掃': false, '設備点検': false, '写真報告書': false, 'マスタメンテナンス': false, },
      // 発注先モーダル開閉
      purchaseModalShow: false,
      // 写真報告選択モーダル開閉
      photoReportModalShow: false,
      // 写真報告書カラーモーダル開閉
      photoReportColorModalShow: false
    }
  }
}
</script>
<style scoped>
.light-green {
  /* color: #71ad73; */
  background: rgb(210, 231, 210);
  width: 100%;
}

.pink {
  /* color: #dd1a83; */
  background: rgb(245 208 208 / 75%);
  width: 100%;
}

.copy:first-letter {
  color: red;
}

.version {
  position: absolute;
  top: 170px;
  left: 60%;
  color: lightslategray;
  font-size: small;
}
</style>